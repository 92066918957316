import {
  CAMP_TYPE_H,
  CAMP_TYPE_K,
  CAMP_TYPE_L,
  CAMP_TYPE_N,
  CAMP_TYPE_P,
  CAMP_TYPE_S,
  CAMP_TYPE_V,
  CAMP_TYPE_VERSTAERKTER_WACHTURM,
  CAMP_TYPE_W,
  CAMP_TYPE_WACHTURM,
  CAMP_TYPE_WALD
} from '../CampTypes';
import {
  UNIT_ALEXDERQUERULANT_ALX_ID,
  UNIT_ALPHAWOLF_AW_ID,
  UNIT_ALPHA_AFFE_AA_ID,
  UNIT_ARMBRUSTSCHÜTZEF_EA_ID,
  UNIT_ASSASSINE_AS_ID,
  UNIT_AUSGEBURTDERNACHT_ADN_ID,
  UNIT_BERITTENEAMAZONE_BA_ID,
  UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID,
  UNIT_BERITTENERBOGENSCHÜTZE_BB_ID,
  UNIT_BERITTENERSEEFAHRER_BSF_ID,
  UNIT_BERSERKER_BR_ID,
  UNIT_BLACK_BEARD_BBE_ID,
  UNIT_BLUE_MAN_BMA_ID,
  UNIT_BOBDERHINTERHÄLTIGE_BOB_ID,
  UNIT_BRÜLLENDERSTIER_BS_ID,
  UNIT_BÄR_BÄR_ID,
  UNIT_CHUCK_CK_ID,
  UNIT_CROAKER_CR_ID,
  UNIT_DECKSCHRUBBER_DSR_ID,
  UNIT_DENISDERDELINQUENT_DEN_ID,
  UNIT_DESERTIERTEMILIZ_DM_ID,
  UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID,
  UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID,
  UNIT_DESERTIERTEREITEREI_DC_ID,
  UNIT_DESERTIERTERELITESOLDAT_DE_ID,
  UNIT_DESERTIERTERKANONIER_DK_ID,
  UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID,
  UNIT_DESERTIERTERREKRUT_DR_ID,
  UNIT_DESERTIERTERSOLDAT_DS_ID,
  UNIT_DICKEBERTHA_DB_ID,
  UNIT_DUNKLERHOHEPRIESTER_DHP_ID,
  UNIT_DUNKLERLORD_DLORD_ID,
  UNIT_DUNKLERPRIESTER_DP_ID,
  UNIT_DUNKLERZAUBERER_DZ_ID,
  UNIT_DÜNENSCHÜTZE_DÜS_ID,
  UNIT_EINFACHERWILDERER_EW_ID,
  UNIT_EINHORN_EH_ID,
  UNIT_EINÄUGIGERBERT_EB_ID,
  UNIT_EISENFAUST_EF_ID,
  UNIT_ELITESOLDATF_EE_ID,
  UNIT_ERGRAUTERRÄUBER_GRAUR_ID,
  UNIT_FANATIKER_FA_ID,
  UNIT_FEUERTÄNZER_FT_ID,
  UNIT_FLEGEL_FL_ID,
  UNIT_FRENCH_MAN_ID,
  UNIT_FROSTADLER_FAD_ID,
  UNIT_FROSTBÄRENMATRIARCHIN_FBM_ID,
  UNIT_FROSTBÄR_FBR_ID,
  UNIT_FROSTFUCHS_FFU_ID,
  UNIT_FROSTLEOPARD_FLE_ID,
  UNIT_FROSTRIESE_FRI_ID,
  UNIT_FROSTSTEINBOCK_FSB_ID,
  UNIT_FROSTWOLF_FWO_ID,
  UNIT_FUCHS_FU_ID,
  UNIT_GARRUNDERFALLENSTELLER_GDF_ID,
  UNIT_GEHEIMNISVOLLERSCHAMANE_GVS_ID,
  UNIT_GEWITZTERRÄUBER_WITZR_ID,
  UNIT_GIERIGERRÄUBER_GIER_ID,
  UNIT_GRAUERWOLF_W_ID,
  UNIT_GRAUSIGERWOLF_GWO_ID,
  UNIT_GREEN_HOOD_GHO_ID,
  UNIT_GREEN_MAN_GMA_ID,
  UNIT_HASENJÄGER_HJ_ID,
  UNIT_HEIMTÜCKISCHERRÄUBER_HTR_ID,
  UNIT_HINTERLISTIGERRÄUBER_HR_ID,
  UNIT_HOCHNÄSIGERRÄUBER_HNR_ID,
  UNIT_HUSKARL_HK_ID,
  UNIT_JAGDHUND_JH_ID,
  UNIT_JAGUAR_KRIEGER_JK_ID,
  UNIT_JOMSWIKINGER_JW_ID,
  UNIT_KANONIERF_EK_ID,
  UNIT_KARL_KAR_ID,
  UNIT_KATAPHRAKT_KP_ID,
  UNIT_KLINGENRASSLER_KRA_ID,
  UNIT_KLUGERRÄUBER_KLUR_ID,
  UNIT_KOMPOSITBOGENSCHÜTZE_KBS_ID,
  UNIT_KRÄHENFÜSSE_KF_ID,
  UNIT_KULTIST_KT_ID,
  UNIT_LANZENREITER_LR_ID,
  UNIT_LUMP_LU_ID,
  UNIT_MAAT_MA_ID,
  UNIT_MESSERWERFER_MW_ID,
  UNIT_METALLGEBISS_MG_ID,
  UNIT_MINIATUR_EISGOLEM_MIG_ID,
  UNIT_MINIATUR_FELSGOLEM_MRG_ID,
  UNIT_MINIATUR_LAVAGOLEM_MLG_ID,
  UNIT_MINIATUR_LEHMGOLEM_MCG_ID,
  UNIT_MYSTERIÖSERRÄUBER_MYSTR_ID,
  UNIT_NOMADE_NO_ID,
  UNIT_NÖRGLER_NÖ_ID,
  UNIT_PETER_SPE_ID,
  UNIT_PISTOLENSCHÜTZE_PS_ID,
  UNIT_PLÜNDERER_PL_ID,
  UNIT_QUENGLER_QU_ID,
  UNIT_RABAUKE_RBK_ID,
  UNIT_RASENDESWILDSCHWEIN_RWS_ID,
  UNIT_RAUFBOLD_RB_ID,
  UNIT_REITER_HM_ID,
  UNIT_RIBBITHA10_RIBC_ID,
  UNIT_RIBBITHA4_RIBA_ID,
  UNIT_RIBBITHA7_RIBB_ID,
  UNIT_RIESENLUCHS_GL_ID,
  UNIT_RIESE_RI_ID,
  UNIT_RIESIGERBÄR_RBÄ_ID,
  UNIT_RÜPEL_RÜ_ID,
  UNIT_SCHAMANE_SCH_ID,
  UNIT_SCHATTENSCHLEICHER_SS_ID,
  UNIT_SCHELM_SM_ID,
  UNIT_SCHLITZOHR_SO_ID,
  UNIT_SCHLÄGER_SL_ID,
  UNIT_SCHURKE_SU_ID,
  UNIT_SCHWARZERSTIER_SST_ID,
  UNIT_SCHWERTMEISTER_SM_ID,
  UNIT_SIRROBIN_ROB_ID,
  UNIT_STAMMESANGEHÖRIGER_SA_ID,
  UNIT_STEINKANONE_STK_ID,
  UNIT_STEINWERFENDERAFFE_SWA_ID,
  UNIT_STEINWERFER_SW_ID,
  UNIT_STINKTIER_ST_ID,
  UNIT_SUMPFHEXE_SH_ID,
  UNIT_SÄBELRASSLER_SÄR_ID,
  UNIT_SÄBELSCHWINGER_SÄS_ID,
  UNIT_TANZENDERDERWISCH_TD_ID,
  UNIT_THRALL_TH_ID,
  UNIT_VANDALE_VND_ID,
  UNIT_VERDORBENERFELSWURM_VFW_ID,
  UNIT_VERDORBENERIESENFLEDERMAUS_VRF_ID,
  UNIT_VERNARBTERRÄUBER_NAR_ID,
  UNIT_VERRÜCKTERSMUTJE_VS_ID,
  UNIT_VERRÜCKTERWISSENSCHAFTLER_MADS_ID,
  UNIT_WACHHUND_WH_ID,
  UNIT_WALDLÄUFER_WL_ID,
  UNIT_WALKÜRE_WK_ID,
  UNIT_WILDEROCHSE_ROX_ID,
  UNIT_WILDEWALTRAUD_WW_ID,
  UNIT_WILDSCHWEIN_WS_ID,
  UNIT_WOLF_WO_ID,
  UNIT_WÜSTENSCHÜTZE_WÜS_ID
} from '../units/Units';
import {
  APOKALYPSE_THEME,
  BaseAdventure as Adventure,
  EREIGNIS_THEME,
  HALLOWEEN_THEME,
  HEIMAT_TYPE,
  KLASSISCH_THEME,
  KOOP_MISSION_TYPE,
  MINI_MISSION_TYPE,
  MISSION_TYPE,
  OSTERN_THEME,
  RETRO_THEME,
  UNTERNEHMUNG_TYPE,
  WEIHNACHTEN_THEME
} from './BaseAdventure';

export const AT_ALTEBEKANNTE_ID = 508;
export const AT_ALTERUINEN_ID = 509;
export const AT_ARCHIPEL_ID = 512;
export const AT_ARKTISCHEEXPLOSION_ID = 513;
export const AT_BEUTELSCHNEIDER_ID = 515;
export const AT_DASENDEDERWELT_ID = 517;
export const AT_DASDUNKLETOR_ID = 520;
export const AT_DERSCHAMANE_ID = 526;
export const AT_DERWIRBELWIND_ID = 527;
export const AT_DERGESTOHLENESCHLITTEN_ID = 528;
export const AT_DERSCHLAFENDEVULKAN_ID = 529;
export const AT_DERVERSCHOLLENESCHÄDEL_ID = 530;
export const AT_DERÜBERRASCHUNGSANGRIFF_ID = 531;
export const AT_DIEBELAGERUNG_ID = 532;
export const AT_DIEDUNKLEBRUDERSCHAFT_ID = 533;
export const AT_DIEFREIBEUTER_RAZZIA_ID = 534;
export const AT_DIEGETEILTESTADTIMHERBST_ID = 535;
export const AT_DIEGETEILTESTADTIMSOMMER_ID = 536;
export const AT_DIEINSELDERFREIBEUTER_ID = 537;
export const AT_DIEINVASIONDERNORDMÄNNER_ID = 538;
export const AT_DIENORDMÄNNER_ID = 539;
export const AT_DIESCHWARZENPRIESTER_ID = 540;
export const AT_DIESCHWARZENRITTER_ID = 541;
export const AT_DIESTÖRENFRIEDE_ID = 542;
export const AT_DIEWILDEWALTRAUT_ID = 544;
export const AT_DROHENDEGEFAHR_ID = 546;
export const AT_EINSAMEEXPERIMENTE_ID = 549;
export const AT_GARRUNDERFALLENSTELLER_ID = 550;
export const AT_GEFÄNGNISINSEL_ID = 551;
export const AT_GRABRÄUBER_ID = 552;
export const AT_HEIMATINSEL_ID = 553;
export const AT_JAGDAUFDIEJÄGER_ID = 555;
export const AT_KOPFGELDJÄGER_ID = 556;
export const AT_MEHREINSAMEEXPERIMENTE_ID = 557;
export const AT_MONSTERAUSDEMNEBEL_ID = 558;
export const AT_MUTTERLIEBE_ID = 559;
export const AT_PIRATENLEBEN_ID = 560;
export const AT_RASENDERBULLE_ID = 561;
export const AT_RETTETDASWEIHNACHTSFEST_2013__ID = 562;
export const AT_RÄUBER_GEGENSCHLAG_ID = 564;
export const AT_RÄUBERBANDE_ID = 565;
export const AT_RÄUBERNEST_ID = 566;
export const AT_RÜCKKEHRINSRÄUBERNEST_ID = 567;
export const AT_SATTELFEST_ID = 568;
export const AT_SCHIESSPULVER_ID = 569;
export const AT_SCHLUMMERNDESRIFF_ID = 570;
export const AT_SEEUFER_SCHATZ_ID = 572;
export const AT_SUMPFHEXE_ID = 575;
export const AT_SÖHNEDERSTEPPE_ID = 576;
export const AT_TROPENSONNE_ID = 578;
export const AT_VERRÄTER_ID = 580;
export const AT_VIKTORDERVERSCHLAGENE_ID = 581;
export const AT_WINTERWUNDERLAND10_ID = 585;
export const AT_WINTERWUNDERLAND4_ID = 586;
export const AT_WINTERWUNDERLAND7_ID = 587;
export const AT_NEBLIGERKUCHEN1_ID = 588;
export const AT_NEBLIGERKUCHEN2_ID = 589;
export const AT_NEBLIGERKUCHEN3_ID = 590;
export const AT_BONABERTI_ID = 591;

export type KlassischeIds =
  | typeof AT_ALTEBEKANNTE_ID
  | typeof AT_ALTERUINEN_ID
  | typeof AT_ARCHIPEL_ID
  | typeof AT_ARKTISCHEEXPLOSION_ID
  | typeof AT_BEUTELSCHNEIDER_ID
  | typeof AT_DASENDEDERWELT_ID
  | typeof AT_DASDUNKLETOR_ID
  | typeof AT_DERSCHAMANE_ID
  | typeof AT_DERWIRBELWIND_ID
  | typeof AT_DERGESTOHLENESCHLITTEN_ID
  | typeof AT_DERSCHLAFENDEVULKAN_ID
  | typeof AT_DERVERSCHOLLENESCHÄDEL_ID
  | typeof AT_DERÜBERRASCHUNGSANGRIFF_ID
  | typeof AT_DIEBELAGERUNG_ID
  | typeof AT_DIEDUNKLEBRUDERSCHAFT_ID
  | typeof AT_DIEFREIBEUTER_RAZZIA_ID
  | typeof AT_DIEGETEILTESTADTIMHERBST_ID
  | typeof AT_DIEGETEILTESTADTIMSOMMER_ID
  | typeof AT_DIEINSELDERFREIBEUTER_ID
  | typeof AT_DIEINVASIONDERNORDMÄNNER_ID
  | typeof AT_DIENORDMÄNNER_ID
  | typeof AT_DIESCHWARZENPRIESTER_ID
  | typeof AT_DIESCHWARZENRITTER_ID
  | typeof AT_DIESTÖRENFRIEDE_ID
  | typeof AT_DIEWILDEWALTRAUT_ID
  | typeof AT_DROHENDEGEFAHR_ID
  | typeof AT_EINSAMEEXPERIMENTE_ID
  | typeof AT_GARRUNDERFALLENSTELLER_ID
  | typeof AT_GEFÄNGNISINSEL_ID
  | typeof AT_GRABRÄUBER_ID
  | typeof AT_HEIMATINSEL_ID
  | typeof AT_JAGDAUFDIEJÄGER_ID
  | typeof AT_KOPFGELDJÄGER_ID
  | typeof AT_MEHREINSAMEEXPERIMENTE_ID
  | typeof AT_MONSTERAUSDEMNEBEL_ID
  | typeof AT_MUTTERLIEBE_ID
  | typeof AT_PIRATENLEBEN_ID
  | typeof AT_RASENDERBULLE_ID
  | typeof AT_RETTETDASWEIHNACHTSFEST_2013__ID
  | typeof AT_RÄUBER_GEGENSCHLAG_ID
  | typeof AT_RÄUBERBANDE_ID
  | typeof AT_RÄUBERNEST_ID
  | typeof AT_RÜCKKEHRINSRÄUBERNEST_ID
  | typeof AT_SATTELFEST_ID
  | typeof AT_SCHIESSPULVER_ID
  | typeof AT_SCHLUMMERNDESRIFF_ID
  | typeof AT_SEEUFER_SCHATZ_ID
  | typeof AT_SUMPFHEXE_ID
  | typeof AT_SÖHNEDERSTEPPE_ID
  | typeof AT_TROPENSONNE_ID
  | typeof AT_VERRÄTER_ID
  | typeof AT_VIKTORDERVERSCHLAGENE_ID
  | typeof AT_WINTERWUNDERLAND10_ID
  | typeof AT_WINTERWUNDERLAND4_ID
  | typeof AT_WINTERWUNDERLAND7_ID
  | typeof AT_NEBLIGERKUCHEN1_ID
  | typeof AT_NEBLIGERKUCHEN2_ID
  | typeof AT_NEBLIGERKUCHEN3_ID
  | typeof AT_BONABERTI_ID;

const AT_ALTEBEKANNTE_ICON = '/icons/adventures/old_friends.png';
const AT_ALTERUINEN_ICON = '/icons/adventures/old_ruins.png';
const AT_ARCHIPEL_ICON = '/icons/adventures/archipel.png';
const AT_ARKTISCHEEXPLOSION_ICON = '/icons/adventures/artic_explosion.png';
const AT_BEUTELSCHNEIDER_ICON = '/icons/adventures/stealing_from_the_rich.png';
const AT_DASENDEDERWELT_ICON = '/icons/adventures/end_of_the_world01.png';
const AT_DASDUNKLETOR_ICON = '/icons/adventures/hw18_dunklesTor.png';
const AT_DERSCHAMANE_ICON = '/icons/adventures/the_shaman.png';
const AT_DERWIRBELWIND_ICON = '/icons/adventures/whirlwind.png';
const AT_DERGESTOHLENESCHLITTEN_ICON = '/icons/adventures/stolen_sleight.png';
const AT_DERSCHLAFENDEVULKAN_ICON = '/icons/adventures/the_sleeping_volcano.png';
const AT_DERVERSCHOLLENESCHÄDEL_ICON = '/icons/adventures/lost_skull.png';
const AT_DERÜBERRASCHUNGSANGRIFF_ICON = '/icons/adventures/surprise_attack.png';
const AT_DIEBELAGERUNG_ICON = '/icons/adventures/the_siege.png';
const AT_DIEDUNKLEBRUDERSCHAFT_ICON = '/icons/adventures/the_dark_brotherhood.png';
const AT_DIEFREIBEUTER_RAZZIA_ICON = '/icons/adventures/buccaneer_roundup.png';
const AT_DIEGETEILTESTADTIMHERBST_ICON = '/icons/adventures/splitCityInFall.png';
const AT_DIEGETEILTESTADTIMSOMMER_ICON = '/icons/adventures/splitCityInSummer.png';
const AT_DIEINSELDERFREIBEUTER_ICON = '/icons/adventures/island_of_the_pirates.png';
const AT_DIEINVASIONDERNORDMÄNNER_ICON = '/icons/adventures/nords02.png';
const AT_DIENORDMÄNNER_ICON = '/icons/adventures/the_nords.png';
const AT_DIESCHWARZENPRIESTER_ICON = '/icons/adventures/the_dark_priests.png';
const AT_DIESCHWARZENRITTER_ICON = '/icons/adventures/the_black_knights.png';
const AT_DIESTÖRENFRIEDE_ICON = '/icons/adventures/party_crashers_hard.png';
const AT_DIEWILDEWALTRAUT_ICON = '/icons/adventures/wild_mary.png';
const AT_DROHENDEGEFAHR_ICON = '/icons/adventures/hw18_drohendeGefahr.png';
const AT_EINSAMEEXPERIMENTE_ICON = '/icons/adventures/secluded_experiments.png';
const AT_GARRUNDERFALLENSTELLER_ICON = '/icons/adventures/garrun.png';
const AT_GEFÄNGNISINSEL_ICON = '/icons/adventures/bastille_island.png';
const AT_GRABRÄUBER_ICON = '/icons/adventures/tomb_raiders.png';
const AT_HEIMATINSEL_ICON = '/icons/adventures/heimat.jpg';
const AT_JAGDAUFDIEJÄGER_ICON = '/icons/adventures/raiding_the_raiders.png';
const AT_KOPFGELDJÄGER_ICON = '/icons/adventures/bounty_hunter.png';
const AT_MEHREINSAMEEXPERIMENTE_ICON = '/icons/adventures/secluded_experiments2.png';
const AT_MONSTERAUSDEMNEBEL_ICON = '/icons/adventures/hw18_MonsterNebel.png';
const AT_MUTTERLIEBE_ICON = '/icons/adventures/motherlove.png';
const AT_PIRATENLEBEN_ICON = '/icons/adventures/pirate_life.png';
const AT_RASENDERBULLE_ICON = '/icons/adventures/roaring_bull.png';
const AT_RETTETDASWEIHNACHTSFEST_2013__ICON = '/icons/adventures/save_christmas.png';
const AT_RÄUBER_GEGENSCHLAG_ICON = '/icons/adventures/Krisenquest.jpeg';
const AT_RÄUBERBANDE_ICON = '/icons/adventures/outlaws.png';
const AT_RÄUBERNEST_ICON = '/icons/adventures/bandit_nest.png';
const AT_RÜCKKEHRINSRÄUBERNEST_ICON = '/icons/adventures/bandit_nest2.png';
const AT_SATTELFEST_ICON = '/icons/adventures/horseback.png';
const AT_SCHIESSPULVER_ICON = '/icons/adventures/gunpowder.png';
const AT_SCHLUMMERNDESRIFF_ICON = '/icons/adventures/the_sleepy_reef.png';
const AT_SEEUFER_SCHATZ_ICON = '/icons/adventures/lakeside_treasure.png';
const AT_SUMPFHEXE_ICON = '/icons/adventures/witch_of_the_swamp.png';
const AT_SÖHNEDERSTEPPE_ICON = '/icons/adventures/sons_of_the_veld.png';
const AT_TROPENSONNE_ICON = '/icons/adventures/tropical_sun.png';
const AT_VERRÄTER_ICON = '/icons/adventures/traitors.png';
const AT_VIKTORDERVERSCHLAGENE_ICON = '/icons/adventures/victor_the_vicious.png';
const AT_WINTERWUNDERLAND10_ICON = '/icons/adventures/winterWonderland2.png';
const AT_WINTERWUNDERLAND4_ICON = '/icons/adventures/winterWonderland2.png';
const AT_WINTERWUNDERLAND7_ICON = '/icons/adventures/winterWonderland2.png';
const AT_NEBLIGERKUCHEN_ICON = '/icons/adventures/nebliger_kuchen.png';
const AT_BONABERTI_ICON = '/icons/adventures/bonaberti.png';

// Maps
const AT_ALTEBEKANNTE_MAP = '/maps/alteBekannte.jpg';
const AT_ALTERUINEN_MAP = '/maps/alteRuinen.jpg';
const AT_ARCHIPEL_MAP = '/maps/archipel.jpg';
const AT_ARKTISCHEEXPLOSION_MAP = '/maps/arktischeExplosion.jpg';
const AT_BEUTELSCHNEIDER_MAP = '/maps/beutelschneider.jpg';
const AT_DASENDEDERWELT_MAP = '/maps/endeDerWelt.jpeg';
const AT_DASDUNKLETOR_MAP = '/maps/hw18_dunklesTor.jpg';
const AT_DERSCHAMANE_MAP = '/maps/schamane.jpg';
const AT_DERWIRBELWIND_MAP = '/maps/wirbelwind.jpg';
const AT_DERGESTOHLENESCHLITTEN_MAP = '/maps/schlitten.jpg';
const AT_DERSCHLAFENDEVULKAN_MAP = '/maps/schlafenderVulkan.jpg';
const AT_DERVERSCHOLLENESCHÄDEL_MAP = '/maps/schaedel.jpg';
const AT_DERÜBERRASCHUNGSANGRIFF_MAP = '/maps/ueAngriff.jpg';
const AT_DIEBELAGERUNG_MAP = '/maps/belagerung.jpg';
const AT_DIEDUNKLEBRUDERSCHAFT_MAP = '/maps/DDB.jpg';
const AT_DIEFREIBEUTER_RAZZIA_MAP = '/maps/freibeuterRazzia.jpg';
const AT_DIEINSELDERFREIBEUTER_MAP = '/maps/IdF.jpg';
const AT_DIEINVASIONDERNORDMÄNNER_MAP = '/maps/nordi2.jpg';
const AT_DIENORDMÄNNER_MAP = '/maps/nordi.jpg';
const AT_DIESCHWARZENPRIESTER_MAP = '/maps/DSP.jpg';
const AT_DIESCHWARZENRITTER_MAP = '/maps/ritter.jpg';
const AT_DIESTÖRENFRIEDE_MAP = '/maps/stoerenfriede.jpg';
const AT_DIEWILDEWALTRAUT_MAP = '/maps/wildeTraude.jpg';
const AT_DROHENDEGEFAHR_MAP = '/maps/hw18_drohendeGefahr.jpg';
const AT_EINSAMEEXPERIMENTE_MAP = '/maps/EinsameExperimente.jpg';
const AT_GARRUNDERFALLENSTELLER_MAP = '/maps/garrun.jpg';
const AT_GEFÄNGNISINSEL_MAP = '/maps/gefaengnisinsel.jpg';
const AT_GRABRÄUBER_MAP = '/maps/grabi.jpg';
const AT_HEIMATINSEL_MAP = '/maps/heimat2.jpg';
const AT_JAGDAUFDIEJÄGER_MAP = '/maps/jagdAufJaeger.jpg';
const AT_KOPFGELDJÄGER_MAP = '/maps/kopfgeld.jpg';
const AT_MEHREINSAMEEXPERIMENTE_MAP = '/maps/MEE.jpg';
const AT_MONSTERAUSDEMNEBEL_MAP = '/maps/hw18_MonsterNebel.jpg';
const AT_MUTTERLIEBE_MAP = '/maps/mutterliebe.jpg';
const AT_PIRATENLEBEN_MAP = '/maps/piratenleben.jpg';
const AT_RASENDERBULLE_MAP = '/maps/bulle.jpg';
const AT_RETTETDASWEIHNACHTSFEST_2013__MAP = '/maps/rettetSanta2013.jpg';
const AT_RÄUBER_GEGENSCHLAG_MAP = '/maps/RaeuberGegenschlag.jpg';
const AT_RÄUBERBANDE_MAP = '/maps/raeuberbande.jpg';
const AT_RÄUBERNEST_MAP = '/maps/raeubernest.jpg';
const AT_RÜCKKEHRINSRÄUBERNEST_MAP = '/maps/RiR.jpg';
const AT_SATTELFEST_MAP = '/maps/sattelfest.jpg';
const AT_SCHIESSPULVER_MAP = '/maps/schiesspulver.jpg';
const AT_SCHLUMMERNDESRIFF_MAP = '/maps/Riff.jpg';
const AT_SEEUFER_SCHATZ_MAP = '/maps/seeuferSchatz.jpg';
const AT_SUMPFHEXE_MAP = '/maps/sumpfhexe.jpg';
const AT_SÖHNEDERSTEPPE_MAP = '/maps/SoehneDerSteppe.jpg';
const AT_TROPENSONNE_MAP = '/maps/Tropensonne.jpg';
const AT_VERRÄTER_MAP = '/maps/verraeter.jpg';
const AT_VIKTORDERVERSCHLAGENE_MAP = '/maps/viktor.jpg';
const AT_WINTERWUNDERLAND10_MAP = '/maps/winterwunderland.jpg';
const AT_WINTERWUNDERLAND4_MAP = '/maps/winterwunderland.jpg';
const AT_WINTERWUNDERLAND7_MAP = '/maps/winterwunderland.jpg';
const AT_NEBLIGERKUCHEN_MAP = '/maps/nebliger_kuchen.jpg';
const AT_DIEGETEILTESTADTIMHERBST_MAP = '/maps/Geteilte_Stadt_im_Herbst.jpeg';
const AT_DIEGETEILTESTADTIMSOMMER_MAP = '/maps/Geteilte_Stadt_im_Sommer.jpeg';
const AT_BONABERTI_MAP = '/maps/Bonaberti.jpg';

export interface KlassischesAt extends Adventure {
  id: KlassischeIds;
}

const Ja = true;
const Nein = false;

const AlteBekannte = {
  name: 'Alte Bekannte',
  image: AT_ALTEBEKANNTE_MAP,
  icon: AT_ALTEBEKANNTE_ICON,
  id: AT_ALTEBEKANNTE_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 5,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_DUNKLERPRIESTER_DP_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_KULTIST_KT_ID },
        { amount: 40, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 40, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 40, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 40, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KULTIST_KT_ID },
        { amount: 10, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 1, typeId: UNIT_DUNKLERHOHEPRIESTER_DHP_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 20, typeId: UNIT_TANZENDERDERWISCH_TD_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_H,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 1, typeId: UNIT_SUMPFHEXE_SH_ID },
        { amount: 1, typeId: UNIT_DUNKLERHOHEPRIESTER_DHP_ID }
      ]
    }
  ]
} as KlassischesAt;
const AlteRuinen = {
  name: 'Alte Ruinen',
  image: AT_ALTERUINEN_MAP,
  icon: AT_ALTERUINEN_ICON,
  id: AT_ALTERUINEN_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: RETRO_THEME,
  difficulty: 6,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 60, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 70, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_EINÄUGIGERBERT_EB_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_GRAUERWOLF_W_ID },
        { amount: 1, typeId: UNIT_CROAKER_CR_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_FANATIKER_FA_ID },
        { amount: 80, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 1, typeId: UNIT_SUMPFHEXE_SH_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 1, typeId: UNIT_SUMPFHEXE_SH_ID }
      ]
    },
    {
      sector: 'C',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [{ amount: 1, typeId: UNIT_AUSGEBURTDERNACHT_ADN_ID }]
    }
  ]
} as KlassischesAt;
const Archipel = {
  name: 'Archipel',
  image: AT_ARCHIPEL_MAP,
  icon: AT_ARCHIPEL_ICON,
  id: AT_ARCHIPEL_ID,
  campaign: ' ',
  type: HEIMAT_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 0,
  allowsElite: Ja,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 190, typeId: UNIT_KLINGENRASSLER_KRA_ID }]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 150, typeId: UNIT_REITER_HM_ID }]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 100, typeId: UNIT_REITER_HM_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_REITER_HM_ID },
        { amount: 90, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID }
      ]
    },
    {
      sector: 'A',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 120, typeId: UNIT_REITER_HM_ID },
        { amount: 1, typeId: UNIT_GEWITZTERRÄUBER_WITZR_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 100, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID }]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 100, typeId: UNIT_REITER_HM_ID }
      ]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 80, typeId: UNIT_REITER_HM_ID },
        { amount: 80, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID }
      ]
    },
    {
      sector: 'B',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 100, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 1, typeId: UNIT_KLUGERRÄUBER_KLUR_ID }
      ]
    },
    {
      sector: 'B',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 110, typeId: UNIT_REITER_HM_ID }
      ]
    },
    {
      sector: 'B',
      number: 7,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID }]
    },
    {
      sector: 'B',
      number: 8,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 100, typeId: UNIT_REITER_HM_ID },
        { amount: 1, typeId: UNIT_MYSTERIÖSERRÄUBER_MYSTR_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 80, typeId: UNIT_REITER_HM_ID },
        { amount: 80, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_STEINKANONE_STK_ID }]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 50, typeId: UNIT_REITER_HM_ID },
        { amount: 70, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID }
      ]
    },
    {
      sector: 'C',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 110, typeId: UNIT_REITER_HM_ID }
      ]
    },
    {
      sector: 'C',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 100, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID }
      ]
    },
    {
      sector: 'C',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 100, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID }
      ]
    },
    {
      sector: 'C',
      number: 7,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 120, typeId: UNIT_STEINKANONE_STK_ID }
      ]
    },
    {
      sector: 'C',
      number: 8,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 60, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 60, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID }
      ]
    },
    {
      sector: 'C',
      number: 9,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 80, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 80, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID }
      ]
    },
    {
      sector: 'C',
      number: 10,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_REITER_HM_ID },
        { amount: 1, typeId: UNIT_HOCHNÄSIGERRÄUBER_HNR_ID }
      ]
    },
    {
      sector: 'C',
      number: 11,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 100, typeId: UNIT_STEINKANONE_STK_ID },
        { amount: 1, typeId: UNIT_ERGRAUTERRÄUBER_GRAUR_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 120, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID }
      ]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 70, typeId: UNIT_REITER_HM_ID },
        { amount: 70, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 190, typeId: UNIT_KLINGENRASSLER_KRA_ID }]
    },
    {
      sector: 'D',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 70, typeId: UNIT_REITER_HM_ID },
        { amount: 70, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID }
      ]
    },
    {
      sector: 'D',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 220, typeId: UNIT_REITER_HM_ID }]
    },
    {
      sector: 'D',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 110, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID }
      ]
    },
    {
      sector: 'D',
      number: 7,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 110, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 100, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 1, typeId: UNIT_HINTERLISTIGERRÄUBER_HR_ID }
      ]
    },
    {
      sector: 'D',
      number: 8,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_SCHWERTMEISTER_SM_ID }]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 100, typeId: UNIT_REITER_HM_ID },
        { amount: 30, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID }
      ]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 120, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID }
      ]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 230, typeId: UNIT_SCHWERTMEISTER_SM_ID }]
    },
    {
      sector: 'E',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 80, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 80, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID }
      ]
    },
    {
      sector: 'E',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 80, typeId: UNIT_REITER_HM_ID },
        { amount: 80, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID }
      ]
    },
    {
      sector: 'E',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 120, typeId: UNIT_REITER_HM_ID },
        { amount: 50, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID }
      ]
    },
    {
      sector: 'E',
      number: 7,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 140, typeId: UNIT_STEINKANONE_STK_ID },
        { amount: 1, typeId: UNIT_HEIMTÜCKISCHERRÄUBER_HTR_ID }
      ]
    },
    {
      sector: 'E',
      number: 8,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 230, typeId: UNIT_KLINGENRASSLER_KRA_ID }]
    },
    {
      sector: 'E',
      number: 9,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 120, typeId: UNIT_REITER_HM_ID }
      ]
    },
    {
      sector: 'E',
      number: 10,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 80, typeId: UNIT_STEINKANONE_STK_ID }
      ]
    },
    {
      sector: 'E',
      number: 11,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 130, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID }
      ]
    },
    {
      sector: 'E',
      number: 12,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 100, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 50, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID }
      ]
    },
    {
      sector: 'E',
      number: 13,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 130, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID }
      ]
    },
    {
      sector: 'E',
      number: 14,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 230, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 1, typeId: UNIT_VERNARBTERRÄUBER_NAR_ID }
      ]
    },
    {
      sector: 'F',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 150, typeId: UNIT_REITER_HM_ID }
      ]
    },
    {
      sector: 'F',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 50, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 100, typeId: UNIT_STEINKANONE_STK_ID }
      ]
    },
    {
      sector: 'F',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 130, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID }
      ]
    },
    {
      sector: 'F',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 250, typeId: UNIT_REITER_HM_ID }]
    },
    {
      sector: 'F',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_REITER_HM_ID },
        { amount: 120, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID }
      ]
    },
    {
      sector: 'F',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 120, typeId: UNIT_STEINKANONE_STK_ID }
      ]
    },
    {
      sector: 'F',
      number: 7,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 60, typeId: UNIT_REITER_HM_ID },
        { amount: 60, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 60, typeId: UNIT_STEINKANONE_STK_ID }
      ]
    },
    {
      sector: 'F',
      number: 8,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 120, typeId: UNIT_STEINKANONE_STK_ID }
      ]
    },
    {
      sector: 'F',
      number: 9,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 80, typeId: UNIT_REITER_HM_ID },
        { amount: 80, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID }
      ]
    },
    {
      sector: 'F',
      number: 10,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 80, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 80, typeId: UNIT_REITER_HM_ID }
      ]
    },
    {
      sector: 'F',
      number: 11,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 90, typeId: UNIT_REITER_HM_ID },
        { amount: 90, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID }
      ]
    },
    {
      sector: 'F',
      number: 12,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_REITER_HM_ID },
        { amount: 70, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 80, typeId: UNIT_STEINKANONE_STK_ID }
      ]
    },
    {
      sector: 'F',
      number: 13,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 90, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 90, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 90, typeId: UNIT_STEINKANONE_STK_ID },
        { amount: 1, typeId: UNIT_GIERIGERRÄUBER_GIER_ID }
      ]
    }
  ]
} as KlassischesAt;
const ArktischeExplosion = {
  name: 'Arktische Explosion',
  image: AT_ARKTISCHEEXPLOSION_MAP,
  icon: AT_ARKTISCHEEXPLOSION_ICON,
  id: AT_ARKTISCHEEXPLOSION_ID,
  campaign: ' ',
  type: MINI_MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 6,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 120, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KARL_KAR_ID },
        { amount: 100, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 180, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 20, typeId: UNIT_JOMSWIKINGER_JW_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 180, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 20, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'A',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 20, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 60, typeId: UNIT_BERSERKER_BR_ID }
      ]
    },
    {
      sector: 'A',
      number: 6,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_KARL_KAR_ID },
        { amount: 80, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 80, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'A',
      number: 7,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_KARL_KAR_ID },
        { amount: 80, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 80, typeId: UNIT_BERSERKER_BR_ID }
      ]
    }
  ]
} as KlassischesAt;
const Beutelschneider = {
  name: 'Beutelschneider',
  image: AT_BEUTELSCHNEIDER_MAP,
  icon: AT_BEUTELSCHNEIDER_ICON,
  id: AT_BEUTELSCHNEIDER_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 5,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 40, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 40, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_DESERTIERTERREKRUT_DR_ID },
        { amount: 40, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 100, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'A',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_DESERTIERTERREKRUT_DR_ID },
        { amount: 30, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID }
      ]
    },
    {
      sector: 'A',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 85, typeId: UNIT_DESERTIERTERREKRUT_DR_ID },
        { amount: 65, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 40, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_DESERTIERTERREKRUT_DR_ID },
        { amount: 40, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 100, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_DESERTIERTERREKRUT_DR_ID },
        { amount: 30, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID }
      ]
    },
    {
      sector: 'B',
      number: 5,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 120, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'B',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 65, typeId: UNIT_DESERTIERTERREKRUT_DR_ID },
        { amount: 85, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID }
      ]
    },
    {
      sector: 'B',
      number: 7,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 20, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 150, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 20, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 40, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'C',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 40, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 1, typeId: UNIT_SIRROBIN_ROB_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 120, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 40, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'D',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 40, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID },
        { amount: 1, typeId: UNIT_SIRROBIN_ROB_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 80, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 40, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 140, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'E',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 20, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 20, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID },
        { amount: 20, typeId: UNIT_DESERTIERTERKANONIER_DK_ID },
        { amount: 1, typeId: UNIT_SIRROBIN_ROB_ID }
      ]
    },
    {
      sector: 'E',
      number: 5,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 40, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'E',
      number: 6,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 80, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'E',
      number: 7,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 40, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'F',
      number: 1,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 160, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'F',
      number: 2,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 140, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'F',
      number: 3,
      type: CAMP_TYPE_W,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 30, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }]
    },
    {
      sector: 'F',
      number: 4,
      type: CAMP_TYPE_W,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 30, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }]
    },
    {
      sector: 'F',
      number: 5,
      type: CAMP_TYPE_W,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 30, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }]
    },
    {
      sector: 'F',
      number: 6,
      type: CAMP_TYPE_W,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 30, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }]
    },
    {
      sector: 'F',
      number: 7,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 30, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 30, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 40, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID },
        { amount: 1, typeId: UNIT_SIRROBIN_ROB_ID }
      ]
    },
    {
      sector: 'F',
      number: 8,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 50, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID },
        { amount: 1, typeId: UNIT_SIRROBIN_ROB_ID }
      ]
    },
    {
      sector: 'F',
      number: 9,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 160, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'G',
      number: 1,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'G',
      number: 2,
      type: CAMP_TYPE_V,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 15, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }]
    },
    {
      sector: 'G',
      number: 3,
      type: CAMP_TYPE_V,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 15, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }]
    },
    {
      sector: 'G',
      number: 4,
      type: CAMP_TYPE_V,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 15, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }]
    },
    {
      sector: 'G',
      number: 5,
      type: CAMP_TYPE_V,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 15, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }]
    },
    {
      sector: 'G',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 50, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 50, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID },
        { amount: 1, typeId: UNIT_DICKEBERTHA_DB_ID }
      ]
    }
  ]
} as KlassischesAt;
const DasEndederWelt = {
  name: 'Das Ende der Welt',
  image: AT_DASENDEDERWELT_MAP,
  icon: AT_DASENDEDERWELT_ICON,
  id: AT_DASENDEDERWELT_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: APOKALYPSE_THEME,
  difficulty: 8,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 30, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 50, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 30, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 50, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 30, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 100, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 100, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }]
    },
    {
      sector: 'A',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 50, typeId: UNIT_SCHAMANE_SCH_ID }
      ]
    },
    {
      sector: 'A',
      number: 6,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 30, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 100, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 30, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 50, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 100, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 50, typeId: UNIT_SCHAMANE_SCH_ID }
      ]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 30, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 50, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'B',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 50, typeId: UNIT_SCHAMANE_SCH_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 100, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 100, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 50, typeId: UNIT_SCHAMANE_SCH_ID }
      ]
    },
    {
      sector: 'C',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 40, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 40, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'C',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 100, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'C',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 40, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 40, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 100, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 100, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 40, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 40, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'D',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 40, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 40, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'D',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 40, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 40, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 30, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 100, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 100, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 40, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 40, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'E',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 100, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }]
    },
    {
      sector: 'E',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 30, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 30, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 100, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'F',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 30, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 100, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'F',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 30, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 50, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'F',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 30, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 100, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'F',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 100, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'F',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 100, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'F',
      number: 6,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 100, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }]
    },
    {
      sector: 'G',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 30, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 50, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'G',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 100, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }]
    },
    {
      sector: 'G',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 30, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 100, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'G',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 50, typeId: UNIT_SCHAMANE_SCH_ID }
      ]
    },
    {
      sector: 'H',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 100, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'H',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 100, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'H',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 30, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 50, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'H',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 30, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 100, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }
      ]
    },
    {
      sector: 'H',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 50, typeId: UNIT_SCHAMANE_SCH_ID }
      ]
    }
  ]
} as KlassischesAt;
const DasdunkleTor = {
  name: 'Das dunkle Tor',
  image: AT_DASDUNKLETOR_MAP,
  icon: AT_DASDUNKLETOR_ICON,
  id: AT_DASDUNKLETOR_ID,
  campaign: 'Die terrorisierte Stadt',
  type: UNTERNEHMUNG_TYPE,
  theme: HALLOWEEN_THEME,
  difficulty: 4,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 50, typeId: UNIT_ALPHAWOLF_AW_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_H,
      art: '',
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_KULTIST_KT_ID },
        { amount: 1, typeId: UNIT_SUMPFHEXE_SH_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_WOLF_WO_ID },
        { amount: 50, typeId: UNIT_ALPHAWOLF_AW_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_H,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 1, typeId: UNIT_SUMPFHEXE_SH_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_FUCHS_FU_ID },
        { amount: 40, typeId: UNIT_BÄR_BÄR_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FUCHS_FU_ID },
        { amount: 30, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 30, typeId: UNIT_WOLF_WO_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_H,
      art: '',
      boss: Ja,
      units: [
        { amount: 55, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 60, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 1, typeId: UNIT_SUMPFHEXE_SH_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_S,
      art: '',
      boss: Ja,
      units: [
        { amount: 10, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 50, typeId: UNIT_WOLF_WO_ID },
        { amount: 1, typeId: UNIT_DUNKLERLORD_DLORD_ID }
      ]
    }
  ]
} as KlassischesAt;

const BonabertiBusiness = {
  name: 'Das Bonaberti-Problem',
  image: AT_BONABERTI_MAP,
  icon: AT_BONABERTI_ICON,
  id: AT_BONABERTI_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 1,
  allowsElite: Ja,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_BLUE_MAN_BMA_ID },
        { amount: 150, typeId: UNIT_GREEN_MAN_GMA_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_BLUE_MAN_BMA_ID },
        { amount: 80, typeId: UNIT_BLACK_BEARD_BBE_ID },
        { amount: 90, typeId: UNIT_GREEN_MAN_GMA_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Ja,
      units: [
        { amount: 1, typeId: UNIT_CHUCK_CK_ID },
        { amount: 60, typeId: UNIT_PETER_SPE_ID },
        { amount: 100, typeId: UNIT_GREEN_MAN_GMA_ID },
        { amount: 100, typeId: UNIT_GREEN_HOOD_GHO_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 105, typeId: UNIT_PETER_SPE_ID },
        { amount: 115, typeId: UNIT_BLACK_BEARD_BBE_ID },
        { amount: 60, typeId: UNIT_GREEN_MAN_GMA_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_BLACK_BEARD_BBE_ID },
        { amount: 140, typeId: UNIT_GREEN_HOOD_GHO_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Ja,
      units: [
        { amount: 1, typeId: UNIT_METALLGEBISS_MG_ID },
        { amount: 200, typeId: UNIT_GREEN_MAN_GMA_ID },
        { amount: 70, typeId: UNIT_GREEN_HOOD_GHO_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 160, typeId: UNIT_PETER_SPE_ID },
        { amount: 50, typeId: UNIT_BLACK_BEARD_BBE_ID },
        { amount: 105, typeId: UNIT_GREEN_MAN_GMA_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 160, typeId: UNIT_BLUE_MAN_BMA_ID },
        { amount: 90, typeId: UNIT_BLACK_BEARD_BBE_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Ja,
      units: [
        { amount: 1, typeId: UNIT_WILDEWALTRAUD_WW_ID },
        { amount: 45, typeId: UNIT_PETER_SPE_ID },
        { amount: 185, typeId: UNIT_BLUE_MAN_BMA_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 260, typeId: UNIT_GREEN_MAN_GMA_ID }]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_PETER_SPE_ID },
        { amount: 15, typeId: UNIT_BLACK_BEARD_BBE_ID },
        { amount: 125, typeId: UNIT_GREEN_MAN_GMA_ID },
        { amount: 105, typeId: UNIT_GREEN_HOOD_GHO_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_PETER_SPE_ID },
        { amount: 100, typeId: UNIT_BLACK_BEARD_BBE_ID },
        { amount: 95, typeId: UNIT_GREEN_HOOD_GHO_ID },
        { amount: 1, typeId: UNIT_STINKTIER_ST_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_BLUE_MAN_BMA_ID },
        { amount: 100, typeId: UNIT_BLACK_BEARD_BBE_ID },
        { amount: 75, typeId: UNIT_GREEN_HOOD_GHO_ID }
      ]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 85, typeId: UNIT_PETER_SPE_ID },
        { amount: 85, typeId: UNIT_BLACK_BEARD_BBE_ID },
        { amount: 90, typeId: UNIT_GREEN_MAN_GMA_ID }
      ]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Ja,
      units: [
        { amount: 130, typeId: UNIT_BLACK_BEARD_BBE_ID },
        { amount: 100, typeId: UNIT_GREEN_HOOD_GHO_ID },
        { amount: 1, typeId: UNIT_EINÄUGIGERBERT_EB_ID }
      ]
    },
    {
      sector: 'F',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_PETER_SPE_ID },
        { amount: 85, typeId: UNIT_BLUE_MAN_BMA_ID },
        { amount: 75, typeId: UNIT_BLACK_BEARD_BBE_ID },
        { amount: 45, typeId: UNIT_GREEN_MAN_GMA_ID }
      ]
    },
    {
      sector: 'F',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 115, typeId: UNIT_BLUE_MAN_BMA_ID },
        { amount: 75, typeId: UNIT_GREEN_MAN_GMA_ID },
        { amount: 120, typeId: UNIT_GREEN_HOOD_GHO_ID }
      ]
    },
    {
      sector: 'F',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Ja,
      units: [
        { amount: 65, typeId: UNIT_PETER_SPE_ID },
        { amount: 100, typeId: UNIT_BLUE_MAN_BMA_ID },
        { amount: 120, typeId: UNIT_GREEN_HOOD_GHO_ID },
        { amount: 1, typeId: UNIT_FRENCH_MAN_ID }
      ]
    }
  ]
} as KlassischesAt;

const DerSchamane = {
  name: 'Der Schamane',
  image: AT_DERSCHAMANE_MAP,
  icon: AT_DERSCHAMANE_ICON,
  id: AT_DERSCHAMANE_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: RETRO_THEME,
  difficulty: 8,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 80, typeId: UNIT_PLÜNDERER_PL_ID }]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 80, typeId: UNIT_STEINWERFER_SW_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 100, typeId: UNIT_SCHLÄGER_SL_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 80, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 80, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 1, typeId: UNIT_STINKTIER_ST_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 80, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 50, typeId: UNIT_STEINWERFER_SW_ID },
        { amount: 40, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'C',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 160, typeId: UNIT_SCHLÄGER_SL_ID }]
    },
    {
      sector: 'C',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_EINÄUGIGERBERT_EB_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_KULTIST_KT_ID },
        { amount: 60, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 80, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 80, typeId: UNIT_FANATIKER_FA_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 40, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 80, typeId: UNIT_TANZENDERDERWISCH_TD_ID }
      ]
    },
    {
      sector: 'D',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 60, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'D',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 1, typeId: UNIT_SUMPFHEXE_SH_ID },
        { amount: 1, typeId: UNIT_GEHEIMNISVOLLERSCHAMANE_GVS_ID }
      ]
    }
  ]
} as KlassischesAt;
const DerWirbelwind = {
  name: 'Der Wirbelwind',
  image: AT_DERWIRBELWIND_MAP,
  icon: AT_DERWIRBELWIND_ICON,
  id: AT_DERWIRBELWIND_ID,
  campaign: ' ',
  type: KOOP_MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 6,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_SCHLÄGER_SL_ID }]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 20, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 99, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_EINÄUGIGERBERT_EB_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_SCHLÄGER_SL_ID }]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 20, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'D',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 99, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_EINÄUGIGERBERT_EB_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'F',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_SCHLÄGER_SL_ID }]
    },
    {
      sector: 'F',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'F',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 20, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'F',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 99, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_EINÄUGIGERBERT_EB_ID }
      ]
    },
    {
      sector: 'G',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'G',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'G',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'H',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_SCHLÄGER_SL_ID }]
    },
    {
      sector: 'H',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'H',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 20, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'H',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 99, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_EINÄUGIGERBERT_EB_ID }
      ]
    },
    {
      sector: 'I',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 90, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    },
    {
      sector: 'I',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 70, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'I',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 60, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_METALLGEBISS_MG_ID }
      ]
    },
    {
      sector: 'J',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 90, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    },
    {
      sector: 'J',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 70, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'J',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 60, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_METALLGEBISS_MG_ID }
      ]
    },
    {
      sector: 'K',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 90, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    },
    {
      sector: 'K',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 70, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'K',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 60, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_METALLGEBISS_MG_ID }
      ]
    },
    {
      sector: 'L',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 90, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    },
    {
      sector: 'L',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 70, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'L',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 60, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_METALLGEBISS_MG_ID }
      ]
    },
    {
      sector: 'M',
      number: 1,
      type: CAMP_TYPE_W,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_STEINWERFER_SW_ID }]
    },
    {
      sector: 'M',
      number: 2,
      type: CAMP_TYPE_W,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_STEINWERFER_SW_ID }]
    },
    {
      sector: 'M',
      number: 3,
      type: CAMP_TYPE_W,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_STEINWERFER_SW_ID }]
    },
    {
      sector: 'M',
      number: 4,
      type: CAMP_TYPE_W,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_STEINWERFER_SW_ID }]
    },
    {
      sector: 'M',
      number: 5,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_CHUCK_CK_ID }
      ]
    }
  ]
} as KlassischesAt;
const DergestohleneSchlitten = {
  name: 'Der gestohlene Schlitten',
  image: AT_DERGESTOHLENESCHLITTEN_MAP,
  icon: AT_DERGESTOHLENESCHLITTEN_ICON,
  id: AT_DERGESTOHLENESCHLITTEN_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: WEIHNACHTEN_THEME,
  difficulty: 7,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 40, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 40, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_KULTIST_KT_ID },
        { amount: 30, typeId: UNIT_TANZENDERDERWISCH_TD_ID }
      ]
    },
    {
      sector: 'A',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_KULTIST_KT_ID },
        { amount: 35, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 1, typeId: UNIT_SUMPFHEXE_SH_ID }
      ]
    },
    {
      sector: 'A',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 90, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 70, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID },
        { amount: 50, typeId: UNIT_DESERTIERTERKANONIER_DK_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 25, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 30, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 30, typeId: UNIT_MAAT_MA_ID },
        { amount: 1, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 80, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID }
      ]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 20, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'B',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_FANATIKER_FA_ID },
        { amount: 80, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 80, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 30, typeId: UNIT_TANZENDERDERWISCH_TD_ID }
      ]
    },
    {
      sector: 'B',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 40, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID }
      ]
    },
    {
      sector: 'B',
      number: 7,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 50, typeId: UNIT_DESERTIERTEREITEREI_DC_ID }
      ]
    },
    {
      sector: 'B',
      number: 8,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 40, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_METALLGEBISS_MG_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 50, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 40, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_FANATIKER_FA_ID },
        { amount: 20, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'C',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 40, typeId: UNIT_FEUERTÄNZER_FT_ID }
      ]
    },
    {
      sector: 'C',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 40, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 30, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID },
        { amount: 1, typeId: UNIT_CROAKER_CR_ID }
      ]
    }
  ]
} as KlassischesAt;
const DerschlafendeVulkan = {
  name: 'Der schlafende Vulkan',
  image: AT_DERSCHLAFENDEVULKAN_MAP,
  icon: AT_DERSCHLAFENDEVULKAN_ICON,
  id: AT_DERSCHLAFENDEVULKAN_ID,
  campaign: ' ',
  type: MINI_MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 2,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_KULTIST_KT_ID },
        { amount: 40, typeId: UNIT_FANATIKER_FA_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KULTIST_KT_ID },
        { amount: 40, typeId: UNIT_DUNKLERPRIESTER_DP_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_KULTIST_KT_ID },
        { amount: 30, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 40, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 40, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 30, typeId: UNIT_TANZENDERDERWISCH_TD_ID }
      ]
    },
    {
      sector: 'A',
      number: 5,
      type: CAMP_TYPE_H,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 40, typeId: UNIT_TANZENDERDERWISCH_TD_ID },
        { amount: 1, typeId: UNIT_DUNKLERHOHEPRIESTER_DHP_ID }
      ]
    }
  ]
} as KlassischesAt;
const DerverscholleneSchÄdel = {
  name: 'Der verschollene Schädel',
  image: AT_DERVERSCHOLLENESCHÄDEL_MAP,
  icon: AT_DERVERSCHOLLENESCHÄDEL_ICON,
  id: AT_DERVERSCHOLLENESCHÄDEL_ID,
  campaign: ' ',
  type: MINI_MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 2,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 120, typeId: UNIT_MESSERWERFER_MW_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 120, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 80, typeId: UNIT_KRÄHENFÜSSE_KF_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 140, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 60, typeId: UNIT_KRÄHENFÜSSE_KF_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 20, typeId: UNIT_MAAT_MA_ID },
        { amount: 1, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID }
      ]
    }
  ]
} as KlassischesAt;
const DerÜberraschungsangriff = {
  name: 'Der Überraschungsangriff',
  image: AT_DERÜBERRASCHUNGSANGRIFF_MAP,
  icon: AT_DERÜBERRASCHUNGSANGRIFF_ICON,
  id: AT_DERÜBERRASCHUNGSANGRIFF_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 7,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 130, typeId: UNIT_KARL_KAR_ID },
        { amount: 70, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_KARL_KAR_ID },
        { amount: 80, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KARL_KAR_ID },
        { amount: 100, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 120, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 80, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 100, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 140, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 60, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_HUSKARL_HK_ID }]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 90, typeId: UNIT_BERSERKER_BR_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 10, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 130, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 70, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 130, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 70, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'C',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 90, typeId: UNIT_BERSERKER_BR_ID }
      ]
    },
    {
      sector: 'C',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 90, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 110, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 80, typeId: UNIT_BERSERKER_BR_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 100, typeId: UNIT_BERSERKER_BR_ID }
      ]
    },
    {
      sector: 'D',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 100, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'D',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 90, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 50, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 160, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 40, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 100, typeId: UNIT_BERSERKER_BR_ID }
      ]
    },
    {
      sector: 'E',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 130, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 70, typeId: UNIT_BERSERKER_BR_ID }
      ]
    },
    {
      sector: 'E',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 120, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 80, typeId: UNIT_BERSERKER_BR_ID }
      ]
    }
  ]
} as KlassischesAt;
const DieBelagerung = {
  name: 'Die Belagerung',
  image: AT_DIEBELAGERUNG_MAP,
  icon: AT_DIEBELAGERUNG_ICON,
  id: AT_DIEBELAGERUNG_ID,
  campaign: ' ',
  type: KOOP_MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 4,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 40, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 30, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 85, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 65, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 40, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 60, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 40, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_STINKTIER_ST_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 40, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 20, typeId: UNIT_ELITESOLDATF_EE_ID },
        { amount: 20, typeId: UNIT_ARMBRUSTSCHÜTZEF_EA_ID },
        { amount: 20, typeId: UNIT_KANONIERF_EK_ID },
        { amount: 1, typeId: UNIT_STINKTIER_ST_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 30, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 30, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 40, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_STINKTIER_ST_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_W,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 15, typeId: UNIT_WALDLÄUFER_WL_ID }]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_W,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 15, typeId: UNIT_WALDLÄUFER_WL_ID }]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_EINÄUGIGERBERT_EB_ID }
      ]
    },
    {
      sector: 'F',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 30, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'F',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 40, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'F',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 85, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 65, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'G',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 40, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'G',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 60, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'G',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 40, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_STINKTIER_ST_ID }
      ]
    },
    {
      sector: 'H',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'H',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 40, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'H',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 20, typeId: UNIT_ELITESOLDATF_EE_ID },
        { amount: 20, typeId: UNIT_ARMBRUSTSCHÜTZEF_EA_ID },
        { amount: 20, typeId: UNIT_KANONIERF_EK_ID },
        { amount: 1, typeId: UNIT_STINKTIER_ST_ID }
      ]
    },
    {
      sector: 'I',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 30, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 30, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 40, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_STINKTIER_ST_ID }
      ]
    },
    {
      sector: 'J',
      number: 1,
      type: CAMP_TYPE_W,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 15, typeId: UNIT_WALDLÄUFER_WL_ID }]
    },
    {
      sector: 'J',
      number: 2,
      type: CAMP_TYPE_W,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 15, typeId: UNIT_WALDLÄUFER_WL_ID }]
    },
    {
      sector: 'J',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_EINÄUGIGERBERT_EB_ID }
      ]
    }
  ]
} as KlassischesAt;
const DieDunkleBruderschaft = {
  name: 'Die Dunkle Bruderschaft',
  image: AT_DIEDUNKLEBRUDERSCHAFT_MAP,
  icon: AT_DIEDUNKLEBRUDERSCHAFT_ICON,
  id: AT_DIEDUNKLEBRUDERSCHAFT_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 8,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 100, typeId: UNIT_FANATIKER_FA_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 100, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 80, typeId: UNIT_FANATIKER_FA_ID },
        { amount: 40, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 40, typeId: UNIT_FANATIKER_FA_ID },
        { amount: 40, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 40, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_KULTIST_KT_ID }]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_KULTIST_KT_ID },
        { amount: 80, typeId: UNIT_DUNKLERPRIESTER_DP_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 70, typeId: UNIT_KULTIST_KT_ID },
        { amount: 60, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 70, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 120, typeId: UNIT_DUNKLERPRIESTER_DP_ID }
      ]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 100, typeId: UNIT_DUNKLERPRIESTER_DP_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 100, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_KULTIST_KT_ID }]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 100, typeId: UNIT_FANATIKER_FA_ID }
      ]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_KULTIST_KT_ID }]
    },
    {
      sector: 'F',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 100, typeId: UNIT_FANATIKER_FA_ID }
      ]
    },
    {
      sector: 'F',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 100, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'G',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 80, typeId: UNIT_FANATIKER_FA_ID },
        { amount: 40, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'G',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 40, typeId: UNIT_FANATIKER_FA_ID },
        { amount: 40, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 40, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'H',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_KULTIST_KT_ID }]
    },
    {
      sector: 'H',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 120, typeId: UNIT_KULTIST_KT_ID },
        { amount: 80, typeId: UNIT_DUNKLERPRIESTER_DP_ID }
      ]
    },
    {
      sector: 'I',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 100, typeId: UNIT_FANATIKER_FA_ID }
      ]
    },
    {
      sector: 'I',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 100, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'J',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 80, typeId: UNIT_FANATIKER_FA_ID },
        { amount: 40, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'J',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 40, typeId: UNIT_FANATIKER_FA_ID },
        { amount: 40, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 40, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'K',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_KULTIST_KT_ID }]
    },
    {
      sector: 'K',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 120, typeId: UNIT_KULTIST_KT_ID },
        { amount: 80, typeId: UNIT_DUNKLERPRIESTER_DP_ID }
      ]
    },
    {
      sector: 'L',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KULTIST_KT_ID },
        { amount: 60, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 70, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'L',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 120, typeId: UNIT_DUNKLERPRIESTER_DP_ID }
      ]
    },
    {
      sector: 'L',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_KULTIST_KT_ID },
        { amount: 150, typeId: UNIT_DUNKLERPRIESTER_DP_ID }
      ]
    },
    {
      sector: 'M',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 120, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'N',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_KULTIST_KT_ID }]
    },
    {
      sector: 'N',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 120, typeId: UNIT_KULTIST_KT_ID },
        { amount: 80, typeId: UNIT_FANATIKER_FA_ID }
      ]
    },
    {
      sector: 'N',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_KULTIST_KT_ID }]
    },
    {
      sector: 'O',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_KULTIST_KT_ID },
        { amount: 100, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'P',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_KULTIST_KT_ID },
        { amount: 70, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 70, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'P',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 125, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'P',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'P',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_FANATIKER_FA_ID },
        { amount: 50, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'P',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_KULTIST_KT_ID },
        { amount: 100, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 49, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 1, typeId: UNIT_DUNKLERHOHEPRIESTER_DHP_ID }
      ]
    },
    {
      sector: 'P',
      number: 6,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_KULTIST_KT_ID },
        { amount: 140, typeId: UNIT_DUNKLERPRIESTER_DP_ID }
      ]
    },
    {
      sector: 'P',
      number: 7,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 100, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'Q',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 66, typeId: UNIT_KULTIST_KT_ID },
        { amount: 66, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 66, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 1, typeId: UNIT_SUMPFHEXE_SH_ID }
      ]
    },
    {
      sector: 'R',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 66, typeId: UNIT_KULTIST_KT_ID },
        { amount: 66, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 66, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'R',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 66, typeId: UNIT_KULTIST_KT_ID },
        { amount: 66, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 66, typeId: UNIT_FEUERTÄNZER_FT_ID }
      ]
    },
    {
      sector: 'S',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 66, typeId: UNIT_KULTIST_KT_ID },
        { amount: 66, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 66, typeId: UNIT_TANZENDERDERWISCH_TD_ID },
        { amount: 2, typeId: UNIT_DUNKLERHOHEPRIESTER_DHP_ID }
      ]
    },
    {
      sector: 'T',
      number: 1,
      type: CAMP_TYPE_H,
      art: '',
      boss: Ja,
      units: [
        { amount: 66, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 66, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 66, typeId: UNIT_TANZENDERDERWISCH_TD_ID },
        { amount: 2, typeId: UNIT_AUSGEBURTDERNACHT_ADN_ID }
      ]
    }
  ]
} as KlassischesAt;
const DieFreibeuter_Razzia = {
  name: 'Die Freibeuter_Razzia',
  image: AT_DIEFREIBEUTER_RAZZIA_MAP,
  icon: AT_DIEFREIBEUTER_RAZZIA_ICON,
  id: AT_DIEFREIBEUTER_RAZZIA_ID,
  campaign: ' ',
  type: KOOP_MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 3,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 80, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 20, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 80, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 120, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 40, typeId: UNIT_MAAT_MA_ID },
        { amount: 40, typeId: UNIT_KANONIERF_EK_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 80, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 20, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 80, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 120, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 40, typeId: UNIT_MAAT_MA_ID },
        { amount: 40, typeId: UNIT_KANONIERF_EK_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 80, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 20, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 80, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 120, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 40, typeId: UNIT_MAAT_MA_ID },
        { amount: 40, typeId: UNIT_KANONIERF_EK_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 50, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 100, typeId: UNIT_KRÄHENFÜSSE_KF_ID }
      ]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 50, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 100, typeId: UNIT_KRÄHENFÜSSE_KF_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 50, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 100, typeId: UNIT_KRÄHENFÜSSE_KF_ID }
      ]
    },
    {
      sector: 'D',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_KRÄHENFÜSSE_KF_ID }]
    },
    {
      sector: 'D',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_KRÄHENFÜSSE_KF_ID }]
    },
    {
      sector: 'D',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 100, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 2, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID },
        { amount: 40, typeId: UNIT_KANONIERF_EK_ID }
      ]
    }
  ]
} as KlassischesAt;
const DiegeteilteStadtimHerbst = {
  name: 'Die geteilte Stadt im Herbst',
  image: AT_DIEGETEILTESTADTIMHERBST_MAP,
  icon: AT_DIEGETEILTESTADTIMHERBST_ICON,
  id: AT_DIEGETEILTESTADTIMHERBST_ID,
  campaign: ' ',
  type: KOOP_MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 13,
  allowsElite: Ja,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 102, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 93, typeId: UNIT_RIESE_RI_ID },
        { amount: 45, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 90, typeId: UNIT_STEINKANONE_STK_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 68, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 82, typeId: UNIT_REITER_HM_ID },
        { amount: 24, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 64, typeId: UNIT_STEINWERFENDERAFFE_SWA_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 258, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 8, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 17, typeId: UNIT_RIESE_RI_ID },
        { amount: 27, typeId: UNIT_STEINWERFENDERAFFE_SWA_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 76, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 51, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 9, typeId: UNIT_EISENFAUST_EF_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 127, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 158, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 57, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 136, typeId: UNIT_ALPHA_AFFE_AA_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 181, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 35, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 140, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 110, typeId: UNIT_STEINKANONE_STK_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 178, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 91, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 79, typeId: UNIT_RIESE_RI_ID },
        { amount: 82, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID }
      ]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 85, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 91, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 66, typeId: UNIT_ALPHA_AFFE_AA_ID },
        { amount: 6, typeId: UNIT_SCHWARZERSTIER_SST_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 78, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 54, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 99, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 73, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 48, typeId: UNIT_RIESE_RI_ID },
        { amount: 134, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 64, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 91, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 113, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 73, typeId: UNIT_RIESE_RI_ID },
        { amount: 105, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 87, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID }
      ]
    },
    {
      sector: 'C',
      number: 4,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 110, typeId: UNIT_REITER_HM_ID },
        { amount: 88, typeId: UNIT_ALPHA_AFFE_AA_ID },
        { amount: 6, typeId: UNIT_ASSASSINE_AS_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 111, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 121, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 45, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 119, typeId: UNIT_ALPHA_AFFE_AA_ID }
      ]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 69, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 113, typeId: UNIT_RIESE_RI_ID },
        { amount: 172, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 102, typeId: UNIT_STEINKANONE_STK_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 108, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 132, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 230, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID }
      ]
    },
    {
      sector: 'D',
      number: 4,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 58, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 54, typeId: UNIT_REITER_HM_ID },
        { amount: 65, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 1, typeId: UNIT_DUNKLERZAUBERER_DZ_ID },
        { amount: 2, typeId: UNIT_ASSASSINE_AS_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 230, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 67, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 50, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 27, typeId: UNIT_ALPHA_AFFE_AA_ID }
      ]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 182, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 176, typeId: UNIT_RIESE_RI_ID },
        { amount: 142, typeId: UNIT_STEINKANONE_STK_ID }
      ]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 156, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 93, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 71, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID }
      ]
    },
    {
      sector: 'E',
      number: 4,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 176, typeId: UNIT_RIESE_RI_ID },
        { amount: 98, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 5, typeId: UNIT_RIESIGERBÄR_RBÄ_ID }
      ]
    },
    {
      sector: 'F',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 147, typeId: UNIT_RIESE_RI_ID },
        { amount: 126, typeId: UNIT_REITER_HM_ID },
        { amount: 71, typeId: UNIT_STEINKANONE_STK_ID }
      ]
    },
    {
      sector: 'F',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 166, typeId: UNIT_REITER_HM_ID },
        { amount: 26, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 147, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID }
      ]
    },
    {
      sector: 'F',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_STEINWERFENDERAFFE_SWA_ID },
        { amount: 54, typeId: UNIT_ALPHA_AFFE_AA_ID }
      ]
    },
    {
      sector: 'F',
      number: 4,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 87, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 43, typeId: UNIT_ALPHA_AFFE_AA_ID },
        { amount: 2, typeId: UNIT_RIESIGERBÄR_RBÄ_ID },
        { amount: 3, typeId: UNIT_SCHWARZERSTIER_SST_ID }
      ]
    },
    {
      sector: 'G',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 87, typeId: UNIT_RIESE_RI_ID },
        { amount: 183, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 149, typeId: UNIT_ALPHA_AFFE_AA_ID }
      ]
    },
    {
      sector: 'G',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 79, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 80, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 45, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID }
      ]
    },
    {
      sector: 'G',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 246, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 96, typeId: UNIT_STEINKANONE_STK_ID }
      ]
    },
    {
      sector: 'G',
      number: 4,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 19, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 100, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 4, typeId: UNIT_DUNKLERZAUBERER_DZ_ID }
      ]
    },
    {
      sector: 'H',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 201, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 76, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 59, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 23, typeId: UNIT_ALPHA_AFFE_AA_ID }
      ]
    },
    {
      sector: 'H',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 125, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 144, typeId: UNIT_RIESE_RI_ID },
        { amount: 39, typeId: UNIT_STEINKANONE_STK_ID },
        { amount: 28, typeId: UNIT_ALPHA_AFFE_AA_ID }
      ]
    },
    {
      sector: 'H',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 173, typeId: UNIT_REITER_HM_ID },
        { amount: 138, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 121, typeId: UNIT_ALPHA_AFFE_AA_ID }
      ]
    },
    {
      sector: 'H',
      number: 4,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 178, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 168, typeId: UNIT_REITER_HM_ID },
        { amount: 2, typeId: UNIT_EISENFAUST_EF_ID },
        { amount: 3, typeId: UNIT_ASSASSINE_AS_ID }
      ]
    },
    {
      sector: 'I',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 85, typeId: UNIT_RIESE_RI_ID },
        { amount: 166, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 84, typeId: UNIT_ALPHA_AFFE_AA_ID }
      ]
    },
    {
      sector: 'I',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 93, typeId: UNIT_RIESE_RI_ID },
        { amount: 67, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 133, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 25, typeId: UNIT_ALPHA_AFFE_AA_ID }
      ]
    },
    {
      sector: 'I',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 135, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 118, typeId: UNIT_STEINWERFENDERAFFE_SWA_ID }
      ]
    },
    {
      sector: 'I',
      number: 4,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 84, typeId: UNIT_REITER_HM_ID },
        { amount: 53, typeId: UNIT_STEINWERFENDERAFFE_SWA_ID },
        { amount: 2, typeId: UNIT_EISENFAUST_EF_ID },
        { amount: 3, typeId: UNIT_SCHWARZERSTIER_SST_ID }
      ]
    },
    {
      sector: 'J',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 88, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 55, typeId: UNIT_REITER_HM_ID },
        { amount: 32, typeId: UNIT_STEINWERFENDERAFFE_SWA_ID }
      ]
    },
    {
      sector: 'J',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 151, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 205, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 101, typeId: UNIT_STEINKANONE_STK_ID }
      ]
    },
    {
      sector: 'J',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 164, typeId: UNIT_REITER_HM_ID },
        { amount: 57, typeId: UNIT_STEINKANONE_STK_ID },
        { amount: 132, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 77, typeId: UNIT_ALPHA_AFFE_AA_ID }
      ]
    },
    {
      sector: 'J',
      number: 4,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 123, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 2, typeId: UNIT_RIESIGERBÄR_RBÄ_ID },
        { amount: 2, typeId: UNIT_SCHWARZERSTIER_SST_ID },
        { amount: 2, typeId: UNIT_DUNKLERZAUBERER_DZ_ID }
      ]
    },
    {
      sector: 'K',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 71, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 18, typeId: UNIT_ALPHA_AFFE_AA_ID }
      ]
    },
    {
      sector: 'K',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 111, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 178, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 183, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID }
      ]
    },
    {
      sector: 'K',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 233, typeId: UNIT_REITER_HM_ID },
        { amount: 60, typeId: UNIT_STEINKANONE_STK_ID },
        { amount: 83, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID }
      ]
    },
    {
      sector: 'K',
      number: 4,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 93, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 97, typeId: UNIT_REITER_HM_ID },
        { amount: 124, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_VERDORBENERIESENFLEDERMAUS_VRF_ID }
      ]
    },
    {
      sector: 'K',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 97, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 133, typeId: UNIT_RIESE_RI_ID },
        { amount: 154, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 44, typeId: UNIT_ALPHA_AFFE_AA_ID }
      ]
    },
    {
      sector: 'K',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 144, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 69, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 135, typeId: UNIT_STEINKANONE_STK_ID },
        { amount: 91, typeId: UNIT_ALPHA_AFFE_AA_ID }
      ]
    },
    {
      sector: 'K',
      number: 7,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 48, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 59, typeId: UNIT_REITER_HM_ID },
        { amount: 145, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 122, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID }
      ]
    },
    {
      sector: 'K',
      number: 8,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 152, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 12, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_VERDORBENERFELSWURM_VFW_ID }
      ]
    }
  ]
} as KlassischesAt;
const DiegeteilteStadtimSommer = {
  name: 'Die geteilte Stadt im Sommer',
  image: AT_DIEGETEILTESTADTIMSOMMER_MAP,
  icon: AT_DIEGETEILTESTADTIMSOMMER_ICON,
  id: AT_DIEGETEILTESTADTIMSOMMER_ID,
  campaign: ' ',
  type: KOOP_MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 9,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 98, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 114, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 150, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 7, typeId: UNIT_FEUERTÄNZER_FT_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 88, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 226, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 26, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 17, typeId: UNIT_RIESE_RI_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 28, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 161, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 94, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 6, typeId: UNIT_FUCHS_FU_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 159, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 203, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 32, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 2, typeId: UNIT_RIESE_RI_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 71, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 102, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 74, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 60, typeId: UNIT_RIESE_RI_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 316, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 42, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 17, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 9, typeId: UNIT_FUCHS_FU_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 321, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 61, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 8, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 4, typeId: UNIT_RIESE_RI_ID }
      ]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 131, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 14, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 199, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 37, typeId: UNIT_FEUERTÄNZER_FT_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 148, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 70, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 26, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 21, typeId: UNIT_DUNKLERPRIESTER_DP_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 2, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 50, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 297, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 57, typeId: UNIT_FEUERTÄNZER_FT_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 274, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 13, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 63, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 10, typeId: UNIT_FUCHS_FU_ID }
      ]
    },
    {
      sector: 'C',
      number: 4,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 216, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 47, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 40, typeId: UNIT_FUCHS_FU_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 373, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 15, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 7, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 2, typeId: UNIT_FEUERTÄNZER_FT_ID }
      ]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 334, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 21, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 4, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 2, typeId: UNIT_FUCHS_FU_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 98, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 112, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 82, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 32, typeId: UNIT_ALPHAWOLF_AW_ID }
      ]
    },
    {
      sector: 'D',
      number: 4,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 131, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 102, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 27, typeId: UNIT_FUCHS_FU_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 239, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 94, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 47, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 12, typeId: UNIT_FUCHS_FU_ID }
      ]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 201, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 98, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 20, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 27, typeId: UNIT_RIESE_RI_ID }
      ]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 275, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 83, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 12, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 8, typeId: UNIT_FUCHS_FU_ID }
      ]
    },
    {
      sector: 'E',
      number: 4,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 266, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 25, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 6, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 1, typeId: UNIT_RIESIGERBÄR_RBÄ_ID }
      ]
    },
    {
      sector: 'F',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 65, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 261, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 38, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 17, typeId: UNIT_FUCHS_FU_ID }
      ]
    },
    {
      sector: 'F',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 187, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 178, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 7, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 10, typeId: UNIT_FUCHS_FU_ID }
      ]
    },
    {
      sector: 'F',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 28, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 143, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 24, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 89, typeId: UNIT_FEUERTÄNZER_FT_ID }
      ]
    },
    {
      sector: 'F',
      number: 4,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 119, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 159, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 11, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 1, typeId: UNIT_RIESIGERBÄR_RBÄ_ID }
      ]
    },
    {
      sector: 'G',
      number: 1,
      type: CAMP_TYPE_WALD,
      art: '',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'G',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 382, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 5, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 4, typeId: UNIT_FUCHS_FU_ID },
        { amount: 2, typeId: UNIT_RIESE_RI_ID }
      ]
    },
    {
      sector: 'G',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 44, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 204, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 144, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 6, typeId: UNIT_DUNKLERPRIESTER_DP_ID }
      ]
    },
    {
      sector: 'G',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 2, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 351, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 35, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 8, typeId: UNIT_ALPHAWOLF_AW_ID }
      ]
    },
    {
      sector: 'G',
      number: 5,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 191, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 96, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 2, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 1, typeId: UNIT_RASENDESWILDSCHWEIN_RWS_ID }
      ]
    },
    {
      sector: 'H',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 384, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 5, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 6, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 4, typeId: UNIT_FUCHS_FU_ID }
      ]
    },
    {
      sector: 'H',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 64, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 57, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 62, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 71, typeId: UNIT_FUCHS_FU_ID }
      ]
    },
    {
      sector: 'H',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 16, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 214, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 141, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 4, typeId: UNIT_FUCHS_FU_ID }
      ]
    },
    {
      sector: 'H',
      number: 4,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 167, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 94, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 19, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 2, typeId: UNIT_RASENDESWILDSCHWEIN_RWS_ID }
      ]
    },
    {
      sector: 'I',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 176, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 62, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 20, typeId: UNIT_RIESE_RI_ID }
      ]
    },
    {
      sector: 'I',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 121, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 250, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 8, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 16, typeId: UNIT_FUCHS_FU_ID }
      ]
    },
    {
      sector: 'I',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 95, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 234, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 14, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 56, typeId: UNIT_FUCHS_FU_ID }
      ]
    },
    {
      sector: 'I',
      number: 4,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 193, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 51, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 52, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 1, typeId: UNIT_RIESIGERBÄR_RBÄ_ID }
      ]
    },
    {
      sector: 'J',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 270, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 121, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 4, typeId: UNIT_DUNKLERPRIESTER_DP_ID }
      ]
    },
    {
      sector: 'J',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 26, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 282, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 68, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 7, typeId: UNIT_BÄR_BÄR_ID }
      ]
    },
    {
      sector: 'J',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 47, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 18, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 5, typeId: UNIT_FUCHS_FU_ID },
        { amount: 265, typeId: UNIT_SCHURKE_SU_ID }
      ]
    },
    {
      sector: 'J',
      number: 4,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 240, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 58, typeId: UNIT_FUCHS_FU_ID },
        { amount: 1, typeId: UNIT_DUNKLERHOHEPRIESTER_DHP_ID }
      ]
    },
    {
      sector: 'K',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 68, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 122, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 69, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 133, typeId: UNIT_FEUERTÄNZER_FT_ID }
      ]
    },
    {
      sector: 'K',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 261, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 41, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 11, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 64, typeId: UNIT_FUCHS_FU_ID }
      ]
    },
    {
      sector: 'K',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 61, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 282, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 35, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 19, typeId: UNIT_FUCHS_FU_ID }
      ]
    },
    {
      sector: 'K',
      number: 4,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 177, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 69, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 1, typeId: UNIT_DUNKLERHOHEPRIESTER_DHP_ID }
      ]
    },
    {
      sector: 'K',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 11, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 35, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 189, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 54, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'K',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 33, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 131, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 32, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 47, typeId: UNIT_ALPHAWOLF_AW_ID }
      ]
    },
    {
      sector: 'K',
      number: 7,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 12, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 88, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 146, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 4, typeId: UNIT_RIESE_RI_ID }
      ]
    },
    {
      sector: 'K',
      number: 8,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 121, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 156, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 20, typeId: UNIT_FUCHS_FU_ID },
        { amount: 1, typeId: UNIT_DUNKLERHOHEPRIESTER_DHP_ID }
      ]
    }
  ]
} as KlassischesAt;
const DieInselderFreibeuter = {
  name: 'Die Insel der Freibeuter',
  image: AT_DIEINSELDERFREIBEUTER_MAP,
  icon: AT_DIEINSELDERFREIBEUTER_ICON,
  id: AT_DIEINSELDERFREIBEUTER_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 2,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 50, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 50, typeId: UNIT_KRÄHENFÜSSE_KF_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 50, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 50, typeId: UNIT_KRÄHENFÜSSE_KF_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 60, typeId: UNIT_MESSERWERFER_MW_ID },
        { amount: 60, typeId: UNIT_KRÄHENFÜSSE_KF_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 40, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 40, typeId: UNIT_KRÄHENFÜSSE_KF_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 80, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 2, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 90, typeId: UNIT_KRÄHENFÜSSE_KF_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 100, typeId: UNIT_KRÄHENFÜSSE_KF_ID }]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 40, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 30, typeId: UNIT_KRÄHENFÜSSE_KF_ID }
      ]
    },
    {
      sector: 'C',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 100, typeId: UNIT_KRÄHENFÜSSE_KF_ID }
      ]
    },
    {
      sector: 'C',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 70, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 10, typeId: UNIT_MAAT_MA_ID },
        { amount: 1, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID }
      ]
    }
  ]
} as KlassischesAt;
const DieInvasionderNordmÄnner = {
  name: 'Die Invasion der Nordmänner',
  image: AT_DIEINVASIONDERNORDMÄNNER_MAP,
  icon: AT_DIEINVASIONDERNORDMÄNNER_ICON,
  id: AT_DIEINVASIONDERNORDMÄNNER_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 9,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_KARL_KAR_ID },
        { amount: 140, typeId: UNIT_JOMSWIKINGER_JW_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_WALKÜRE_WK_ID }]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KARL_KAR_ID },
        { amount: 50, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KARL_KAR_ID },
        { amount: 120, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_KARL_KAR_ID },
        { amount: 160, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'B',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KARL_KAR_ID },
        { amount: 120, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'B',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_WALKÜRE_WK_ID }]
    },
    {
      sector: 'B',
      number: 7,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_THRALL_TH_ID },
        { amount: 80, typeId: UNIT_HUSKARL_HK_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_THRALL_TH_ID },
        { amount: 80, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 40, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 85, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 80, typeId: UNIT_WALKÜRE_WK_ID },
        { amount: 35, typeId: UNIT_BERSERKER_BR_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_THRALL_TH_ID },
        { amount: 80, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 50, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_KARL_KAR_ID }]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_KARL_KAR_ID },
        { amount: 75, typeId: UNIT_HUSKARL_HK_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_KARL_KAR_ID },
        { amount: 80, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 60, typeId: UNIT_JOMSWIKINGER_JW_ID }
      ]
    },
    {
      sector: 'D',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_KARL_KAR_ID },
        { amount: 50, typeId: UNIT_HUSKARL_HK_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_KARL_KAR_ID },
        { amount: 80, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 80, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 120, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 40, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 40, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 120, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'F',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_KARL_KAR_ID },
        { amount: 80, typeId: UNIT_HUSKARL_HK_ID }
      ]
    },
    {
      sector: 'F',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 150, typeId: UNIT_HUSKARL_HK_ID }]
    },
    {
      sector: 'F',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 100, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'F',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_WALKÜRE_WK_ID }]
    },
    {
      sector: 'F',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 100, typeId: UNIT_HUSKARL_HK_ID }]
    },
    {
      sector: 'F',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 40, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 60, typeId: UNIT_WALKÜRE_WK_ID },
        { amount: 20, typeId: UNIT_BERSERKER_BR_ID }
      ]
    },
    {
      sector: 'G',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KARL_KAR_ID },
        { amount: 120, typeId: UNIT_HUSKARL_HK_ID }
      ]
    },
    {
      sector: 'G',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 100, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'G',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_KARL_KAR_ID },
        { amount: 50, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 70, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 30, typeId: UNIT_BERSERKER_BR_ID }
      ]
    },
    {
      sector: 'G',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 180, typeId: UNIT_HUSKARL_HK_ID }]
    },
    {
      sector: 'H',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_HUSKARL_HK_ID }]
    },
    {
      sector: 'H',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_WALKÜRE_WK_ID }]
    },
    {
      sector: 'H',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_KARL_KAR_ID }]
    },
    {
      sector: 'H',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 60, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 60, typeId: UNIT_BERSERKER_BR_ID }
      ]
    }
  ]
} as KlassischesAt;
const DieNordmÄnner = {
  name: 'Die Nordmänner',
  image: AT_DIENORDMÄNNER_MAP,
  icon: AT_DIENORDMÄNNER_ICON,
  id: AT_DIENORDMÄNNER_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 6,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KARL_KAR_ID },
        { amount: 50, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KARL_KAR_ID },
        { amount: 75, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 150, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_THRALL_TH_ID },
        { amount: 50, typeId: UNIT_HUSKARL_HK_ID }
      ]
    },
    {
      sector: 'A',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_THRALL_TH_ID },
        { amount: 50, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 25, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'A',
      number: 6,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KARL_KAR_ID },
        { amount: 50, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'A',
      number: 7,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KARL_KAR_ID },
        { amount: 75, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'A',
      number: 8,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_THRALL_TH_ID },
        { amount: 50, typeId: UNIT_HUSKARL_HK_ID }
      ]
    },
    {
      sector: 'A',
      number: 9,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_THRALL_TH_ID },
        { amount: 50, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 25, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'A',
      number: 10,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 75, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 50, typeId: UNIT_WALKÜRE_WK_ID },
        { amount: 5, typeId: UNIT_BERSERKER_BR_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 150, typeId: UNIT_KARL_KAR_ID }]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_KARL_KAR_ID },
        { amount: 50, typeId: UNIT_HUSKARL_HK_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_KARL_KAR_ID },
        { amount: 75, typeId: UNIT_HUSKARL_HK_ID }
      ]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KARL_KAR_ID },
        { amount: 50, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 50, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'B',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 50, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'B',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 10, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 50, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_KARL_KAR_ID },
        { amount: 50, typeId: UNIT_HUSKARL_HK_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 50, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 100, typeId: UNIT_HUSKARL_HK_ID }]
    },
    {
      sector: 'C',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 75, typeId: UNIT_WALKÜRE_WK_ID }]
    },
    {
      sector: 'C',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 20, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 50, typeId: UNIT_WALKÜRE_WK_ID },
        { amount: 15, typeId: UNIT_BERSERKER_BR_ID }
      ]
    },
    {
      sector: 'C',
      number: 6,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 75, typeId: UNIT_HUSKARL_HK_ID }]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 25, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KARL_KAR_ID },
        { amount: 75, typeId: UNIT_HUSKARL_HK_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 120, typeId: UNIT_HUSKARL_HK_ID }]
    },
    {
      sector: 'D',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_KARL_KAR_ID },
        { amount: 100, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 20, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 15, typeId: UNIT_BERSERKER_BR_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [{ amount: 150, typeId: UNIT_HUSKARL_HK_ID }]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [{ amount: 150, typeId: UNIT_WALKÜRE_WK_ID }]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [{ amount: 150, typeId: UNIT_KARL_KAR_ID }]
    },
    {
      sector: 'E',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 120, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 50, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 30, typeId: UNIT_BERSERKER_BR_ID }
      ]
    }
  ]
} as KlassischesAt;
const DieSchwarzenPriester = {
  name: 'Die Schwarzen Priester',
  image: AT_DIESCHWARZENPRIESTER_MAP,
  icon: AT_DIESCHWARZENPRIESTER_ICON,
  id: AT_DIESCHWARZENPRIESTER_ID,
  campaign: ' ',
  type: MINI_MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 3,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 20, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 20, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 20, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_STINKTIER_ST_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 10, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 10, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 50, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'A',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 10, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 15, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'A',
      number: 6,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 50, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'A',
      number: 7,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 30, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'A',
      number: 8,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 10, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 15, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'A',
      number: 9,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 10, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 15, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'A',
      number: 10,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 40, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_KULTIST_KT_ID },
        { amount: 40, typeId: UNIT_DUNKLERPRIESTER_DP_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 30, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_KULTIST_KT_ID },
        { amount: 10, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 20, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_K,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_KULTIST_KT_ID },
        { amount: 30, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 20, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 1, typeId: UNIT_SUMPFHEXE_SH_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_KULTIST_KT_ID },
        { amount: 20, typeId: UNIT_DUNKLERPRIESTER_DP_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 25, typeId: UNIT_KULTIST_KT_ID },
        { amount: 30, typeId: UNIT_FANATIKER_FA_ID },
        { amount: 10, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 25, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FANATIKER_FA_ID },
        { amount: 10, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 30, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 30, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_S,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_KULTIST_KT_ID },
        { amount: 40, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 40, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 1, typeId: UNIT_DUNKLERHOHEPRIESTER_DHP_ID }
      ]
    }
  ]
} as KlassischesAt;
const DieSchwarzenRitter = {
  name: 'Die Schwarzen Ritter',
  image: AT_DIESCHWARZENRITTER_MAP,
  icon: AT_DIESCHWARZENRITTER_ICON,
  id: AT_DIESCHWARZENRITTER_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 5,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 100, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID },
        { amount: 100, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 100, typeId: UNIT_DESERTIERTEREITEREI_DC_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 10, typeId: UNIT_DESERTIERTERKANONIER_DK_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 100, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID },
        { amount: 100, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 100, typeId: UNIT_DESERTIERTEREITEREI_DC_ID }
      ]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 10, typeId: UNIT_DESERTIERTERKANONIER_DK_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 100, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID },
        { amount: 100, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 100, typeId: UNIT_DESERTIERTEREITEREI_DC_ID }
      ]
    },
    {
      sector: 'C',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 10, typeId: UNIT_DESERTIERTERKANONIER_DK_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID }
      ]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID }
      ]
    },
    {
      sector: 'D',
      number: 4,
      type: CAMP_TYPE_S,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 50, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 50, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID },
        { amount: 1, typeId: UNIT_SIRROBIN_ROB_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 10, typeId: UNIT_DESERTIERTERKANONIER_DK_ID }
      ]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 60, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 40, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID }
      ]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 140, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID },
        { amount: 30, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID }
      ]
    },
    {
      sector: 'E',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID },
        { amount: 20, typeId: UNIT_DESERTIERTERKANONIER_DK_ID }
      ]
    },
    {
      sector: 'F',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 90, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID }
      ]
    },
    {
      sector: 'F',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 80, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID }
      ]
    },
    {
      sector: 'F',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 25, typeId: UNIT_DESERTIERTERKANONIER_DK_ID }
      ]
    },
    {
      sector: 'F',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 100, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID }
      ]
    },
    {
      sector: 'F',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 90, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID }
      ]
    },
    {
      sector: 'F',
      number: 6,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID },
        { amount: 100, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID }
      ]
    },
    {
      sector: 'F',
      number: 7,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 75, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID }
      ]
    },
    {
      sector: 'F',
      number: 8,
      type: CAMP_TYPE_S,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 50, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID },
        { amount: 25, typeId: UNIT_DESERTIERTERKANONIER_DK_ID },
        { amount: 1, typeId: UNIT_DICKEBERTHA_DB_ID }
      ]
    }
  ]
} as KlassischesAt;
const DieStÖrenfriede = {
  name: 'Die Störenfriede',
  image: AT_DIESTÖRENFRIEDE_MAP,
  icon: AT_DIESTÖRENFRIEDE_ICON,
  id: AT_DIESTÖRENFRIEDE_ID,
  campaign: ' ',
  type: HEIMAT_TYPE,
  theme: OSTERN_THEME,
  difficulty: 0,
  allowsElite: Ja,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 10, typeId: UNIT_SCHLITZOHR_SO_ID },
        { amount: 10, typeId: UNIT_RÜPEL_RÜ_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_SCHLITZOHR_SO_ID },
        { amount: 10, typeId: UNIT_SCHELM_SM_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_SCHLITZOHR_SO_ID },
        { amount: 10, typeId: UNIT_VANDALE_VND_ID },
        { amount: 10, typeId: UNIT_SCHELM_SM_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 25, typeId: UNIT_VANDALE_VND_ID },
        { amount: 30, typeId: UNIT_SCHELM_SM_ID },
        { amount: 20, typeId: UNIT_RÜPEL_RÜ_ID }
      ]
    },
    {
      sector: 'A',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 70, typeId: UNIT_SCHLITZOHR_SO_ID },
        { amount: 50, typeId: UNIT_SCHELM_SM_ID },
        { amount: 1, typeId: UNIT_DENISDERDELINQUENT_DEN_ID }
      ]
    },
    {
      sector: 'A',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_VANDALE_VND_ID },
        { amount: 50, typeId: UNIT_SCHELM_SM_ID },
        { amount: 30, typeId: UNIT_SCHURKE_SU_ID }
      ]
    },
    {
      sector: 'A',
      number: 7,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_VANDALE_VND_ID },
        { amount: 50, typeId: UNIT_SCHELM_SM_ID },
        { amount: 20, typeId: UNIT_LUMP_LU_ID }
      ]
    },
    {
      sector: 'A',
      number: 8,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_VANDALE_VND_ID },
        { amount: 20, typeId: UNIT_RABAUKE_RBK_ID },
        { amount: 70, typeId: UNIT_SCHELM_SM_ID }
      ]
    },
    {
      sector: 'A',
      number: 9,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_VANDALE_VND_ID },
        { amount: 50, typeId: UNIT_SCHURKE_SU_ID }
      ]
    },
    {
      sector: 'A',
      number: 10,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_RABAUKE_RBK_ID },
        { amount: 20, typeId: UNIT_LUMP_LU_ID },
        { amount: 1, typeId: UNIT_ALEXDERQUERULANT_ALX_ID }
      ]
    },
    {
      sector: 'A',
      number: 11,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_RABAUKE_RBK_ID },
        { amount: 100, typeId: UNIT_FLEGEL_FL_ID },
        { amount: 30, typeId: UNIT_LUMP_LU_ID }
      ]
    },
    {
      sector: 'A',
      number: 12,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_RABAUKE_RBK_ID },
        { amount: 50, typeId: UNIT_RÜPEL_RÜ_ID },
        { amount: 70, typeId: UNIT_LUMP_LU_ID }
      ]
    },
    {
      sector: 'A',
      number: 13,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_NÖRGLER_NÖ_ID },
        { amount: 100, typeId: UNIT_QUENGLER_QU_ID }
      ]
    },
    {
      sector: 'A',
      number: 14,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_RABAUKE_RBK_ID },
        { amount: 50, typeId: UNIT_NÖRGLER_NÖ_ID },
        { amount: 150, typeId: UNIT_QUENGLER_QU_ID }
      ]
    },
    {
      sector: 'A',
      number: 15,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_NÖRGLER_NÖ_ID },
        { amount: 50, typeId: UNIT_LUMP_LU_ID },
        { amount: 1, typeId: UNIT_BOBDERHINTERHÄLTIGE_BOB_ID }
      ]
    },
    {
      sector: 'A',
      number: 16,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_NÖRGLER_NÖ_ID },
        { amount: 1, typeId: UNIT_DENISDERDELINQUENT_DEN_ID },
        { amount: 1, typeId: UNIT_ALEXDERQUERULANT_ALX_ID },
        { amount: 1, typeId: UNIT_BOBDERHINTERHÄLTIGE_BOB_ID }
      ]
    }
  ]
} as KlassischesAt;
const DieWildeWaltraut = {
  name: 'Die Wilde Waltraut',
  image: AT_DIEWILDEWALTRAUT_MAP,
  icon: AT_DIEWILDEWALTRAUT_ICON,
  id: AT_DIEWILDEWALTRAUT_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 8,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 70, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 70, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 70, typeId: UNIT_STEINWERFER_SW_ID },
        { amount: 30, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 70, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 30, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 70, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 70, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 70, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 180, typeId: UNIT_RAUFBOLD_RB_ID }]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 60, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'D',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    },
    {
      sector: 'D',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 130, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 70, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 130, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 120, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_RAUFBOLD_RB_ID }]
    },
    {
      sector: 'E',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 80, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 70, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'E',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 140, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 60, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'E',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 60, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_WILDEWALTRAUD_WW_ID }
      ]
    }
  ]
} as KlassischesAt;
const DrohendeGefahr = {
  name: 'Drohende Gefahr',
  image: AT_DROHENDEGEFAHR_MAP,
  icon: AT_DROHENDEGEFAHR_ICON,
  id: AT_DROHENDEGEFAHR_ID,
  campaign: 'Die terrorisierte Stadt',
  type: MINI_MISSION_TYPE,
  theme: HALLOWEEN_THEME,
  difficulty: 4,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 100, typeId: UNIT_FUCHS_FU_ID }]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FUCHS_FU_ID },
        { amount: 30, typeId: UNIT_WOLF_WO_ID },
        { amount: 15, typeId: UNIT_BÄR_BÄR_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 1, typeId: UNIT_EINHORN_EH_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 30, typeId: UNIT_BÄR_BÄR_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_FUCHS_FU_ID },
        { amount: 35, typeId: UNIT_BÄR_BÄR_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 45, typeId: UNIT_FUCHS_FU_ID },
        { amount: 15, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 1, typeId: UNIT_RIESIGERBÄR_RBÄ_ID }
      ]
    }
  ]
} as KlassischesAt;
const EinsameExperimente = {
  name: 'Einsame Experimente',
  image: AT_EINSAMEEXPERIMENTE_MAP,
  icon: AT_EINSAMEEXPERIMENTE_ICON,
  id: AT_EINSAMEEXPERIMENTE_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 9,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 100, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 100, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 100, typeId: UNIT_MAAT_MA_ID },
        { amount: 1, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 100, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 100, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 100, typeId: UNIT_MAAT_MA_ID },
        { amount: 1, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 50, typeId: UNIT_KRÄHENFÜSSE_KF_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 100, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_MAAT_MA_ID },
        { amount: 100, typeId: UNIT_KRÄHENFÜSSE_KF_ID }
      ]
    },
    {
      sector: 'C',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 50, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 100, typeId: UNIT_MAAT_MA_ID },
        { amount: 1, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 50, typeId: UNIT_MAAT_MA_ID },
        { amount: 2, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID }
      ]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 50, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_SÄBELRASSLER_SÄR_ID }]
    },
    {
      sector: 'D',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 110, typeId: UNIT_MAAT_MA_ID }]
    },
    {
      sector: 'D',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 50, typeId: UNIT_KRÄHENFÜSSE_KF_ID }
      ]
    },
    {
      sector: 'D',
      number: 6,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 100, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 50, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'D',
      number: 7,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 50, typeId: UNIT_MAAT_MA_ID },
        { amount: 2, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID }
      ]
    },
    {
      sector: 'D',
      number: 8,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 50, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'D',
      number: 9,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 100, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID }
      ]
    },
    {
      sector: 'D',
      number: 10,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID }]
    },
    {
      sector: 'D',
      number: 11,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 50, typeId: UNIT_KRÄHENFÜSSE_KF_ID }
      ]
    },
    {
      sector: 'D',
      number: 12,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 75, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'D',
      number: 13,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 50, typeId: UNIT_MAAT_MA_ID },
        { amount: 3, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 100, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 125, typeId: UNIT_MAAT_MA_ID },
        { amount: 1, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID }
      ]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 100, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'E',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 3, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID }
      ]
    },
    {
      sector: 'E',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 100, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'E',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 125, typeId: UNIT_MAAT_MA_ID },
        { amount: 1, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID }
      ]
    },
    {
      sector: 'E',
      number: 7,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 50, typeId: UNIT_KRÄHENFÜSSE_KF_ID }
      ]
    },
    {
      sector: 'F',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 50, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 75, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'F',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 100, typeId: UNIT_SÄBELRASSLER_SÄR_ID }
      ]
    },
    {
      sector: 'F',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 75, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 75, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 2, typeId: UNIT_BRÜLLENDERSTIER_BS_ID }
      ]
    },
    {
      sector: 'F',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 75, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'G',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 75, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'G',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_SÄBELRASSLER_SÄR_ID }]
    },
    {
      sector: 'G',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 125, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 50, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 2, typeId: UNIT_BRÜLLENDERSTIER_BS_ID }
      ]
    },
    {
      sector: 'G',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 75, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'G',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [{ amount: 100, typeId: UNIT_MAAT_MA_ID }]
    },
    {
      sector: 'G',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 125, typeId: UNIT_MAAT_MA_ID },
        { amount: 3, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID }
      ]
    },
    {
      sector: 'G',
      number: 7,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID }]
    },
    {
      sector: 'H',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_SÄBELRASSLER_SÄR_ID }]
    },
    {
      sector: 'H',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 100, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'H',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 100, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'H',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 50, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 75, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'H',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 3, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID }
      ]
    },
    {
      sector: 'I',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 50, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 75, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'I',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 100, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID }
      ]
    },
    {
      sector: 'I',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 50, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 75, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'I',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 75, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 100, typeId: UNIT_MAAT_MA_ID },
        { amount: 1, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID }
      ]
    },
    {
      sector: 'J',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_SÄBELRASSLER_SÄR_ID }]
    },
    {
      sector: 'J',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 125, typeId: UNIT_MAAT_MA_ID }]
    },
    {
      sector: 'J',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 100, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 2, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID }
      ]
    },
    {
      sector: 'J',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 75, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'J',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 75, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'J',
      number: 6,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 75, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'J',
      number: 7,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 75, typeId: UNIT_MAAT_MA_ID },
        { amount: 2, typeId: UNIT_BRÜLLENDERSTIER_BS_ID }
      ]
    },
    {
      sector: 'J',
      number: 8,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID }]
    },
    {
      sector: 'K',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 75, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'K',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 100, typeId: UNIT_MAAT_MA_ID },
        { amount: 1, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID }
      ]
    },
    {
      sector: 'L',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 100, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'L',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 100, typeId: UNIT_KRÄHENFÜSSE_KF_ID }
      ]
    },
    {
      sector: 'L',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 50, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 100, typeId: UNIT_MAAT_MA_ID },
        { amount: 1, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID }
      ]
    }
  ]
} as KlassischesAt;
const GarrunderFallensteller = {
  name: 'Garrun der Fallensteller',
  image: AT_GARRUNDERFALLENSTELLER_MAP,
  icon: AT_GARRUNDERFALLENSTELLER_ICON,
  id: AT_GARRUNDERFALLENSTELLER_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: OSTERN_THEME,
  difficulty: 5,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 120, typeId: UNIT_EINFACHERWILDERER_EW_ID }]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_EINFACHERWILDERER_EW_ID },
        { amount: 40, typeId: UNIT_JAGDHUND_JH_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_EINFACHERWILDERER_EW_ID },
        { amount: 120, typeId: UNIT_HASENJÄGER_HJ_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_EINFACHERWILDERER_EW_ID },
        { amount: 140, typeId: UNIT_JAGDHUND_JH_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_HASENJÄGER_HJ_ID },
        { amount: 50, typeId: UNIT_JAGDHUND_JH_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_EINFACHERWILDERER_EW_ID },
        { amount: 50, typeId: UNIT_HASENJÄGER_HJ_ID },
        { amount: 30, typeId: UNIT_JAGDHUND_JH_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_EINFACHERWILDERER_EW_ID },
        { amount: 80, typeId: UNIT_JAGDHUND_JH_ID }
      ]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [{ amount: 200, typeId: UNIT_JAGDHUND_JH_ID }]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_EINFACHERWILDERER_EW_ID },
        { amount: 150, typeId: UNIT_JAGDHUND_JH_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_EINFACHERWILDERER_EW_ID },
        { amount: 30, typeId: UNIT_HASENJÄGER_HJ_ID },
        { amount: 70, typeId: UNIT_JAGDHUND_JH_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 1, typeId: UNIT_GARRUNDERFALLENSTELLER_GDF_ID },
        { amount: 49, typeId: UNIT_EINFACHERWILDERER_EW_ID },
        { amount: 50, typeId: UNIT_HASENJÄGER_HJ_ID },
        { amount: 100, typeId: UNIT_JAGDHUND_JH_ID }
      ]
    }
  ]
} as KlassischesAt;
const GefÄngnisinsel = {
  name: 'Gefängnisinsel',
  image: AT_GEFÄNGNISINSEL_MAP,
  icon: AT_GEFÄNGNISINSEL_ICON,
  id: AT_GEFÄNGNISINSEL_ID,
  campaign: ' ',
  type: MINI_MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 6,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_S,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_NOMADE_NO_ID },
        { amount: 80, typeId: UNIT_LANZENREITER_LR_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_LANZENREITER_LR_ID },
        { amount: 50, typeId: UNIT_KATAPHRAKT_KP_ID },
        { amount: 1, typeId: UNIT_BRÜLLENDERSTIER_BS_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_S,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 80, typeId: UNIT_KRÄHENFÜSSE_KF_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_P,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 50, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 1, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_S,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FANATIKER_FA_ID },
        { amount: 80, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_H,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 50, typeId: UNIT_TANZENDERDERWISCH_TD_ID },
        { amount: 1, typeId: UNIT_SUMPFHEXE_SH_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_S,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 50, typeId: UNIT_WALKÜRE_WK_ID }
      ]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 30, typeId: UNIT_KARL_KAR_ID },
        { amount: 30, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 40, typeId: UNIT_BERSERKER_BR_ID }
      ]
    }
  ]
} as KlassischesAt;
const GrabrÄuber = {
  name: 'Grabräuber',
  image: AT_GRABRÄUBER_MAP,
  icon: AT_GRABRÄUBER_ICON,
  id: AT_GRABRÄUBER_ID,
  campaign: ' ',
  type: KOOP_MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 6,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 50, typeId: UNIT_TANZENDERDERWISCH_TD_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KULTIST_KT_ID },
        { amount: 100, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 50, typeId: UNIT_TANZENDERDERWISCH_TD_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 48, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 2, typeId: UNIT_DUNKLERHOHEPRIESTER_DHP_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 50, typeId: UNIT_TANZENDERDERWISCH_TD_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KULTIST_KT_ID },
        { amount: 100, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 50, typeId: UNIT_TANZENDERDERWISCH_TD_ID }
      ]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 48, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 2, typeId: UNIT_DUNKLERHOHEPRIESTER_DHP_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 50, typeId: UNIT_TANZENDERDERWISCH_TD_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KULTIST_KT_ID },
        { amount: 100, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 50, typeId: UNIT_TANZENDERDERWISCH_TD_ID }
      ]
    },
    {
      sector: 'C',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 48, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 2, typeId: UNIT_DUNKLERHOHEPRIESTER_DHP_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 50, typeId: UNIT_TANZENDERDERWISCH_TD_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KULTIST_KT_ID },
        { amount: 100, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 50, typeId: UNIT_TANZENDERDERWISCH_TD_ID }
      ]
    },
    {
      sector: 'D',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 48, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 2, typeId: UNIT_DUNKLERHOHEPRIESTER_DHP_ID }
      ]
    }
  ]
} as KlassischesAt;
const Heimatinsel = {
  name: 'Heimatinsel',
  image: AT_HEIMATINSEL_MAP,
  icon: AT_HEIMATINSEL_ICON,
  id: AT_HEIMATINSEL_ID,
  campaign: ' ',
  type: HEIMAT_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 0,
  allowsElite: Ja,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 6, typeId: UNIT_PLÜNDERER_PL_ID }]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [{ amount: 12, typeId: UNIT_PLÜNDERER_PL_ID }]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 3, typeId: UNIT_PLÜNDERER_PL_ID }]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 10, typeId: UNIT_PLÜNDERER_PL_ID }]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 20, typeId: UNIT_PLÜNDERER_PL_ID }]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 15, typeId: UNIT_PLÜNDERER_PL_ID }]
    },
    {
      sector: 'B',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 25, typeId: UNIT_PLÜNDERER_PL_ID }]
    },
    {
      sector: 'B',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 18, typeId: UNIT_PLÜNDERER_PL_ID }]
    },
    {
      sector: 'B',
      number: 7,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 10, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'B',
      number: 8,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 15, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 20, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'B',
      number: 9,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 20, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'B',
      number: 10,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 30, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 20, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 30, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 60, typeId: UNIT_PLÜNDERER_PL_ID }]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 80, typeId: UNIT_PLÜNDERER_PL_ID }]
    },
    {
      sector: 'C',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 20, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'C',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 20, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'C',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 100, typeId: UNIT_PLÜNDERER_PL_ID }]
    },
    {
      sector: 'C',
      number: 7,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 1, typeId: UNIT_STINKTIER_ST_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 40, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 10, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 5, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 5, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'D',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 50, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'D',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 5, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 85, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'D',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 79, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 1, typeId: UNIT_EINÄUGIGERBERT_EB_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 120, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 120, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 30, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 130, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'E',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 120, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 40, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'E',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 30, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 20, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'E',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 49, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_CHUCK_CK_ID }
      ]
    },
    {
      sector: 'F',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 180, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 20, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'F',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 40, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 60, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'F',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 180, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 20, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'F',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 40, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'F',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 149, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_METALLGEBISS_MG_ID }
      ]
    },
    {
      sector: 'G',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_WALDLÄUFER_WL_ID }]
    },
    {
      sector: 'G',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'G',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 140, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'G',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 110, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 40, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'G',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 10, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 10, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 180, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'G',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_RAUFBOLD_RB_ID }]
    },
    {
      sector: 'G',
      number: 7,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 20, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 120, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'G',
      number: 8,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_WACHHUND_WH_ID }]
    },
    {
      sector: 'G',
      number: 9,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 160, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 10, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'G',
      number: 10,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 170, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 20, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 10, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'G',
      number: 11,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 60, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 70, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'G',
      number: 12,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 80, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 70, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'G',
      number: 13,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 70, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'G',
      number: 14,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 199, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 1, typeId: UNIT_WILDEWALTRAUD_WW_ID }
      ]
    }
  ]
} as KlassischesAt;
const JagdaufdieJÄger = {
  name: 'Jagd auf die Jäger',
  image: AT_JAGDAUFDIEJÄGER_MAP,
  icon: AT_JAGDAUFDIEJÄGER_ICON,
  id: AT_JAGDAUFDIEJÄGER_ID,
  campaign: ' ',
  type: KOOP_MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 4,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_NOMADE_NO_ID },
        { amount: 100, typeId: UNIT_LANZENREITER_LR_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 180, typeId: UNIT_LANZENREITER_LR_ID }]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_NOMADE_NO_ID },
        { amount: 50, typeId: UNIT_BERITTENEAMAZONE_BA_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_NOMADE_NO_ID },
        { amount: 50, typeId: UNIT_LANZENREITER_LR_ID },
        { amount: 48, typeId: UNIT_BERITTENEAMAZONE_BA_ID },
        { amount: 2, typeId: UNIT_BRÜLLENDERSTIER_BS_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_NOMADE_NO_ID },
        { amount: 100, typeId: UNIT_LANZENREITER_LR_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 180, typeId: UNIT_LANZENREITER_LR_ID }]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_NOMADE_NO_ID },
        { amount: 50, typeId: UNIT_BERITTENEAMAZONE_BA_ID }
      ]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_NOMADE_NO_ID },
        { amount: 50, typeId: UNIT_LANZENREITER_LR_ID },
        { amount: 48, typeId: UNIT_BERITTENEAMAZONE_BA_ID },
        { amount: 2, typeId: UNIT_BRÜLLENDERSTIER_BS_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_NOMADE_NO_ID },
        { amount: 100, typeId: UNIT_LANZENREITER_LR_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 180, typeId: UNIT_LANZENREITER_LR_ID }]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_NOMADE_NO_ID },
        { amount: 50, typeId: UNIT_BERITTENEAMAZONE_BA_ID }
      ]
    },
    {
      sector: 'C',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_NOMADE_NO_ID },
        { amount: 50, typeId: UNIT_LANZENREITER_LR_ID },
        { amount: 48, typeId: UNIT_BERITTENEAMAZONE_BA_ID },
        { amount: 2, typeId: UNIT_BRÜLLENDERSTIER_BS_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_WACHTURM,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_NOMADE_NO_ID },
        { amount: 100, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BB_ID }
      ]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_VERSTAERKTER_WACHTURM,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_NOMADE_NO_ID },
        { amount: 150, typeId: UNIT_BERITTENEAMAZONE_BA_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 49, typeId: UNIT_NOMADE_NO_ID },
        { amount: 50, typeId: UNIT_KOMPOSITBOGENSCHÜTZE_KBS_ID },
        { amount: 100, typeId: UNIT_KATAPHRAKT_KP_ID },
        { amount: 1, typeId: UNIT_BRÜLLENDERSTIER_BS_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_WACHTURM,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_NOMADE_NO_ID },
        { amount: 100, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BB_ID }
      ]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_VERSTAERKTER_WACHTURM,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_NOMADE_NO_ID },
        { amount: 150, typeId: UNIT_BERITTENEAMAZONE_BA_ID }
      ]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 49, typeId: UNIT_NOMADE_NO_ID },
        { amount: 50, typeId: UNIT_KOMPOSITBOGENSCHÜTZE_KBS_ID },
        { amount: 100, typeId: UNIT_KATAPHRAKT_KP_ID },
        { amount: 1, typeId: UNIT_BRÜLLENDERSTIER_BS_ID }
      ]
    },
    {
      sector: 'F',
      number: 1,
      type: CAMP_TYPE_WACHTURM,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_NOMADE_NO_ID },
        { amount: 100, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BB_ID }
      ]
    },
    {
      sector: 'F',
      number: 2,
      type: CAMP_TYPE_VERSTAERKTER_WACHTURM,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_NOMADE_NO_ID },
        { amount: 150, typeId: UNIT_BERITTENEAMAZONE_BA_ID }
      ]
    },
    {
      sector: 'F',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 49, typeId: UNIT_NOMADE_NO_ID },
        { amount: 50, typeId: UNIT_KOMPOSITBOGENSCHÜTZE_KBS_ID },
        { amount: 100, typeId: UNIT_KATAPHRAKT_KP_ID },
        { amount: 1, typeId: UNIT_BRÜLLENDERSTIER_BS_ID }
      ]
    }
  ]
} as KlassischesAt;
const KopfgeldjÄger = {
  name: 'Kopfgeldjäger',
  image: AT_KOPFGELDJÄGER_MAP,
  icon: AT_KOPFGELDJÄGER_ICON,
  id: AT_KOPFGELDJÄGER_ID,
  campaign: ' ',
  type: MINI_MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 1,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [{ amount: 10, typeId: UNIT_PLÜNDERER_PL_ID }]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 5, typeId: UNIT_PLÜNDERER_PL_ID }]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 10, typeId: UNIT_PLÜNDERER_PL_ID }]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 6, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 6, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [{ amount: 15, typeId: UNIT_PLÜNDERER_PL_ID }]
    }
  ]
} as KlassischesAt;
const MehreinsameExperimente = {
  name: 'Mehr einsame Experimente',
  image: AT_MEHREINSAMEEXPERIMENTE_MAP,
  icon: AT_MEHREINSAMEEXPERIMENTE_ICON,
  id: AT_MEHREINSAMEEXPERIMENTE_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 7,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 50, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 100, typeId: UNIT_DESERTIERTEREITEREI_DC_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_DESERTIERTERREKRUT_DR_ID },
        { amount: 80, typeId: UNIT_DESERTIERTEMILIZ_DM_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_DESERTIERTERREKRUT_DR_ID },
        { amount: 80, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'A',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTERREKRUT_DR_ID },
        { amount: 50, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 50, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 50, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 40, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 150, typeId: UNIT_DESERTIERTEMILIZ_DM_ID }]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 40, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID },
        { amount: 50, typeId: UNIT_DESERTIERTEREITEREI_DC_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID },
        { amount: 100, typeId: UNIT_DESERTIERTEREITEREI_DC_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 80, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 90, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'C',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_DESERTIERTERREKRUT_DR_ID },
        { amount: 1, typeId: UNIT_DICKEBERTHA_DB_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_DESERTIERTERREKRUT_DR_ID },
        { amount: 80, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID }
      ]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 90, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 1, typeId: UNIT_DICKEBERTHA_DB_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 100, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 1, typeId: UNIT_SUMPFHEXE_SH_ID }
      ]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 40, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID },
        { amount: 80, typeId: UNIT_DESERTIERTEREITEREI_DC_ID }
      ]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 40, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID }
      ]
    },
    {
      sector: 'F',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_DESERTIERTEREITEREI_DC_ID }]
    },
    {
      sector: 'F',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 80, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID }
      ]
    },
    {
      sector: 'F',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 1, typeId: UNIT_DICKEBERTHA_DB_ID }
      ]
    },
    {
      sector: 'G',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 120, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID }]
    },
    {
      sector: 'G',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 70, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 70, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID }
      ]
    },
    {
      sector: 'G',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 100, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 40, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'H',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 100, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID }
      ]
    },
    {
      sector: 'H',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID },
        { amount: 50, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 50, typeId: UNIT_DESERTIERTERKANONIER_DK_ID }
      ]
    },
    {
      sector: 'H',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 90, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID }
      ]
    },
    {
      sector: 'H',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID },
        { amount: 1, typeId: UNIT_DICKEBERTHA_DB_ID }
      ]
    },
    {
      sector: 'I',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_DESERTIERTERREKRUT_DR_ID },
        { amount: 60, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERKANONIER_DK_ID }
      ]
    },
    {
      sector: 'I',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 100, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 1, typeId: UNIT_DUNKLERHOHEPRIESTER_DHP_ID }
      ]
    },
    {
      sector: 'I',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 100, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 1, typeId: UNIT_DUNKLERHOHEPRIESTER_DHP_ID }
      ]
    },
    {
      sector: 'I',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 30, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 1, typeId: UNIT_AUSGEBURTDERNACHT_ADN_ID }
      ]
    }
  ]
} as KlassischesAt;
const MonsterausdemNebel = {
  name: 'Monster aus dem Nebel',
  image: AT_MONSTERAUSDEMNEBEL_MAP,
  icon: AT_MONSTERAUSDEMNEBEL_ICON,
  id: AT_MONSTERAUSDEMNEBEL_ID,
  campaign: 'Die terrorisierte Stadt',
  type: UNTERNEHMUNG_TYPE,
  theme: HALLOWEEN_THEME,
  difficulty: 4,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_W,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_MINIATUR_LEHMGOLEM_MCG_ID },
        { amount: 50, typeId: UNIT_MINIATUR_LAVAGOLEM_MLG_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_W,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_MINIATUR_EISGOLEM_MIG_ID },
        { amount: 40, typeId: UNIT_MINIATUR_FELSGOLEM_MRG_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_W,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_MINIATUR_LEHMGOLEM_MCG_ID },
        { amount: 30, typeId: UNIT_MINIATUR_EISGOLEM_MIG_ID },
        { amount: 30, typeId: UNIT_MINIATUR_LAVAGOLEM_MLG_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_L,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_MINIATUR_LAVAGOLEM_MLG_ID },
        { amount: 1, typeId: UNIT_VERRÜCKTERWISSENSCHAFTLER_MADS_ID }
      ]
    }
  ]
} as KlassischesAt;
const Mutterliebe = {
  name: 'Mutterliebe',
  image: AT_MUTTERLIEBE_MAP,
  icon: AT_MUTTERLIEBE_ICON,
  id: AT_MUTTERLIEBE_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 3,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 100, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 100, typeId: UNIT_KRÄHENFÜSSE_KF_ID }]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 1, typeId: UNIT_WILDEWALTRAUD_WW_ID },
        { amount: 50, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 50, typeId: UNIT_MAAT_MA_ID },
        { amount: 1, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID }
      ]
    },
    {
      sector: 'A',
      number: 5,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'A',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 100, typeId: UNIT_MAAT_MA_ID }
      ]
    }
  ]
} as KlassischesAt;
const Piratenleben = {
  name: 'Piratenleben',
  image: AT_PIRATENLEBEN_MAP,
  icon: AT_PIRATENLEBEN_ICON,
  id: AT_PIRATENLEBEN_ID,
  campaign: ' ',
  type: MINI_MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 5,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 80, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_WACHHUND_WH_ID }]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_METALLGEBISS_MG_ID }
      ]
    },
    {
      sector: 'A',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_CHUCK_CK_ID }
      ]
    }
  ]
} as KlassischesAt;
const RasenderBulle = {
  name: 'Rasender Bulle',
  image: AT_RASENDERBULLE_MAP,
  icon: AT_RASENDERBULLE_ICON,
  id: AT_RASENDERBULLE_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 6,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_NOMADE_NO_ID },
        { amount: 100, typeId: UNIT_KOMPOSITBOGENSCHÜTZE_KBS_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_NOMADE_NO_ID },
        { amount: 50, typeId: UNIT_LANZENREITER_LR_ID },
        { amount: 70, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BB_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_NOMADE_NO_ID },
        { amount: 100, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BB_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_NOMADE_NO_ID },
        { amount: 50, typeId: UNIT_KOMPOSITBOGENSCHÜTZE_KBS_ID },
        { amount: 30, typeId: UNIT_LANZENREITER_LR_ID }
      ]
    },
    {
      sector: 'A',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 70, typeId: UNIT_LANZENREITER_LR_ID },
        { amount: 80, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BB_ID },
        { amount: 20, typeId: UNIT_BERITTENEAMAZONE_BA_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_NOMADE_NO_ID },
        { amount: 40, typeId: UNIT_LANZENREITER_LR_ID },
        { amount: 80, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BB_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_LANZENREITER_LR_ID },
        { amount: 120, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BB_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_NOMADE_NO_ID },
        { amount: 70, typeId: UNIT_LANZENREITER_LR_ID },
        { amount: 40, typeId: UNIT_BERITTENEAMAZONE_BA_ID }
      ]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_NOMADE_NO_ID },
        { amount: 80, typeId: UNIT_LANZENREITER_LR_ID },
        { amount: 50, typeId: UNIT_KATAPHRAKT_KP_ID }
      ]
    },
    {
      sector: 'B',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 70, typeId: UNIT_LANZENREITER_LR_ID },
        { amount: 80, typeId: UNIT_BERITTENEAMAZONE_BA_ID },
        { amount: 40, typeId: UNIT_KATAPHRAKT_KP_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_NOMADE_NO_ID },
        { amount: 80, typeId: UNIT_KOMPOSITBOGENSCHÜTZE_KBS_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_NOMADE_NO_ID },
        { amount: 30, typeId: UNIT_KOMPOSITBOGENSCHÜTZE_KBS_ID },
        { amount: 40, typeId: UNIT_LANZENREITER_LR_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_LANZENREITER_LR_ID },
        { amount: 80, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BB_ID }
      ]
    },
    {
      sector: 'C',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_NOMADE_NO_ID },
        { amount: 50, typeId: UNIT_LANZENREITER_LR_ID },
        { amount: 60, typeId: UNIT_BERITTENEAMAZONE_BA_ID }
      ]
    },
    {
      sector: 'C',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_NOMADE_NO_ID },
        { amount: 100, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BB_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_NOMADE_NO_ID },
        { amount: 150, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BB_ID }
      ]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_NOMADE_NO_ID },
        { amount: 90, typeId: UNIT_BERITTENEAMAZONE_BA_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_LANZENREITER_LR_ID },
        { amount: 100, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BB_ID }
      ]
    },
    {
      sector: 'D',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_NOMADE_NO_ID },
        { amount: 50, typeId: UNIT_LANZENREITER_LR_ID },
        { amount: 80, typeId: UNIT_BERITTENEAMAZONE_BA_ID }
      ]
    },
    {
      sector: 'D',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_NOMADE_NO_ID },
        { amount: 70, typeId: UNIT_BERITTENEAMAZONE_BA_ID },
        { amount: 40, typeId: UNIT_KATAPHRAKT_KP_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_NOMADE_NO_ID },
        { amount: 120, typeId: UNIT_KOMPOSITBOGENSCHÜTZE_KBS_ID }
      ]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_NOMADE_NO_ID },
        { amount: 50, typeId: UNIT_KOMPOSITBOGENSCHÜTZE_KBS_ID }
      ]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_NOMADE_NO_ID },
        { amount: 60, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BB_ID }
      ]
    },
    {
      sector: 'E',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_NOMADE_NO_ID },
        { amount: 50, typeId: UNIT_LANZENREITER_LR_ID },
        { amount: 80, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BB_ID }
      ]
    },
    {
      sector: 'E',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_LANZENREITER_LR_ID },
        { amount: 40, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BB_ID },
        { amount: 60, typeId: UNIT_BERITTENEAMAZONE_BA_ID }
      ]
    },
    {
      sector: 'E',
      number: 6,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_NOMADE_NO_ID },
        { amount: 80, typeId: UNIT_LANZENREITER_LR_ID }
      ]
    },
    {
      sector: 'F',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_NOMADE_NO_ID },
        { amount: 40, typeId: UNIT_BERITTENEAMAZONE_BA_ID },
        { amount: 50, typeId: UNIT_KATAPHRAKT_KP_ID }
      ]
    },
    {
      sector: 'F',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_LANZENREITER_LR_ID },
        { amount: 100, typeId: UNIT_KATAPHRAKT_KP_ID }
      ]
    },
    {
      sector: 'F',
      number: 3,
      type: CAMP_TYPE_H,
      art: '',
      boss: Ja,
      units: [
        { amount: 70, typeId: UNIT_NOMADE_NO_ID },
        { amount: 100, typeId: UNIT_LANZENREITER_LR_ID },
        { amount: 30, typeId: UNIT_KATAPHRAKT_KP_ID },
        { amount: 1, typeId: UNIT_BRÜLLENDERSTIER_BS_ID }
      ]
    },
    {
      sector: 'F',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_NOMADE_NO_ID },
        { amount: 60, typeId: UNIT_LANZENREITER_LR_ID },
        { amount: 50, typeId: UNIT_KATAPHRAKT_KP_ID }
      ]
    },
    {
      sector: 'G',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 100, typeId: UNIT_FEUERTÄNZER_FT_ID }
      ]
    },
    {
      sector: 'G',
      number: 2,
      type: CAMP_TYPE_H,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 120, typeId: UNIT_FEUERTÄNZER_FT_ID }
      ]
    },
    {
      sector: 'G',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_KULTIST_KT_ID },
        { amount: 60, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 100, typeId: UNIT_FEUERTÄNZER_FT_ID }
      ]
    },
    {
      sector: 'G',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KULTIST_KT_ID },
        { amount: 80, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'G',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 20, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 80, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'H',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 60, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 40, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'H',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_FEUERTÄNZER_FT_ID }
      ]
    },
    {
      sector: 'H',
      number: 3,
      type: CAMP_TYPE_H,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 70, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 1, typeId: UNIT_DUNKLERHOHEPRIESTER_DHP_ID }
      ]
    }
  ]
} as KlassischesAt;
const RettetdasWeihnachtsfest_2013_ = {
  name: 'Rettet das Weihnachtsfest (2013)',
  image: AT_RETTETDASWEIHNACHTSFEST_2013__MAP,
  icon: AT_RETTETDASWEIHNACHTSFEST_2013__ICON,
  id: AT_RETTETDASWEIHNACHTSFEST_2013__ID,
  campaign: ' ',
  type: MINI_MISSION_TYPE,
  theme: WEIHNACHTEN_THEME,
  difficulty: 3,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 40, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 40, typeId: UNIT_KRÄHENFÜSSE_KF_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 40, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_NOMADE_NO_ID },
        { amount: 40, typeId: UNIT_KOMPOSITBOGENSCHÜTZE_KBS_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_KULTIST_KT_ID },
        { amount: 40, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 50, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_MESSERWERFER_MW_ID },
        { amount: 50, typeId: UNIT_KULTIST_KT_ID },
        { amount: 30, typeId: UNIT_HUSKARL_HK_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 20, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 40, typeId: UNIT_DESERTIERTEREITEREI_DC_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 20, typeId: UNIT_KULTIST_KT_ID },
        { amount: 20, typeId: UNIT_NOMADE_NO_ID }
      ]
    },
    {
      sector: 'C',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_NOMADE_NO_ID },
        { amount: 60, typeId: UNIT_LANZENREITER_LR_ID },
        { amount: 1, typeId: UNIT_CROAKER_CR_ID }
      ]
    }
  ]
} as KlassischesAt;
const RÄuber_Gegenschlag = {
  name: 'Räuber Gegenschlag',
  image: AT_RÄUBER_GEGENSCHLAG_MAP,
  icon: AT_RÄUBER_GEGENSCHLAG_ICON,
  id: AT_RÄUBER_GEGENSCHLAG_ID,
  campaign: ' ',
  type: HEIMAT_TYPE,
  theme: EREIGNIS_THEME,
  difficulty: 0,
  allowsElite: Ja,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 75, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 50, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 50, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 125, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 50, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 100, typeId: UNIT_STEINWERFER_SW_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 25, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 175, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 100, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 125, typeId: UNIT_STEINWERFER_SW_ID },
        { amount: 75, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 100, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 125, typeId: UNIT_STEINWERFER_SW_ID },
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 60, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 55, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 500, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 250, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 250, typeId: UNIT_STEINWERFER_SW_ID },
        { amount: 300, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 150, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 150, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'F',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 650, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 375, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 375, typeId: UNIT_STEINWERFER_SW_ID },
        { amount: 350, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 225, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 200, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    }
  ]
} as KlassischesAt;
const RÄuberbande = {
  name: 'Räuberbande',
  image: AT_RÄUBERBANDE_MAP,
  icon: AT_RÄUBERBANDE_ICON,
  id: AT_RÄUBERBANDE_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 7,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 20, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 50, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 60, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 50, typeId: UNIT_STEINWERFER_SW_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 50, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 50, typeId: UNIT_STEINWERFER_SW_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_STEINWERFER_SW_ID },
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 1, typeId: UNIT_STINKTIER_ST_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 60, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 40, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 150, typeId: UNIT_RAUFBOLD_RB_ID }]
    },
    {
      sector: 'C',
      number: 6,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 40, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 60, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 7,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 2, typeId: UNIT_STINKTIER_ST_ID },
        { amount: 1, typeId: UNIT_METALLGEBISS_MG_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 60, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_STEINWERFER_SW_ID },
        { amount: 60, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 80, typeId: UNIT_PLÜNDERER_PL_ID }]
    },
    {
      sector: 'D',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 80, typeId: UNIT_STEINWERFER_SW_ID }]
    },
    {
      sector: 'D',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 80, typeId: UNIT_PLÜNDERER_PL_ID }]
    },
    {
      sector: 'D',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 30, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 80, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 30, typeId: UNIT_STEINWERFER_SW_ID },
        { amount: 30, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 100, typeId: UNIT_STEINWERFER_SW_ID },
        { amount: 30, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 60, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 60, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'E',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 60, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 60, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'E',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 180, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 1, typeId: UNIT_EINÄUGIGERBERT_EB_ID }
      ]
    }
  ]
} as KlassischesAt;
const RÄubernest = {
  name: 'Räubernest',
  image: AT_RÄUBERNEST_MAP,
  icon: AT_RÄUBERNEST_ICON,
  id: AT_RÄUBERNEST_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 7,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_SCHLÄGER_SL_ID }]
    },
    {
      sector: 'A',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_EINÄUGIGERBERT_EB_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 20, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 60, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_METALLGEBISS_MG_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 70, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 90, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_CHUCK_CK_ID }
      ]
    }
  ]
} as KlassischesAt;
const RÜckkehrinsRÄubernest = {
  name: 'Rückkehr ins Räubernest',
  image: AT_RÜCKKEHRINSRÄUBERNEST_MAP,
  icon: AT_RÜCKKEHRINSRÄUBERNEST_ICON,
  id: AT_RÜCKKEHRINSRÄUBERNEST_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 4,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 60, typeId: UNIT_STEINWERFER_SW_ID },
        { amount: 60, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 20, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 80, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 150, typeId: UNIT_SCHLÄGER_SL_ID }]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 70, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 80, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 60, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 60, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 60, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 60, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_METALLGEBISS_MG_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 20, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 1, typeId: UNIT_STINKTIER_ST_ID },
        { amount: 1, typeId: UNIT_CHUCK_CK_ID }
      ]
    }
  ]
} as KlassischesAt;
const Sattelfest = {
  name: 'Sattelfest',
  image: AT_SATTELFEST_MAP,
  icon: AT_SATTELFEST_ICON,
  id: AT_SATTELFEST_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 4,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_NOMADE_NO_ID },
        { amount: 40, typeId: UNIT_LANZENREITER_LR_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 90, typeId: UNIT_BERITTENEAMAZONE_BA_ID },
        { amount: 10, typeId: UNIT_BRÜLLENDERSTIER_BS_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_NOMADE_NO_ID },
        { amount: 40, typeId: UNIT_BERITTENEAMAZONE_BA_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_NOMADE_NO_ID },
        { amount: 40, typeId: UNIT_LANZENREITER_LR_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 70, typeId: UNIT_NOMADE_NO_ID },
        { amount: 20, typeId: UNIT_KATAPHRAKT_KP_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_NOMADE_NO_ID },
        { amount: 10, typeId: UNIT_BERITTENEAMAZONE_BA_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BB_ID },
        { amount: 30, typeId: UNIT_BERITTENEAMAZONE_BA_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_NOMADE_NO_ID },
        { amount: 75, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BB_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_NOMADE_NO_ID },
        { amount: 70, typeId: UNIT_LANZENREITER_LR_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 60, typeId: UNIT_LANZENREITER_LR_ID }]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 120, typeId: UNIT_KOMPOSITBOGENSCHÜTZE_KBS_ID },
        { amount: 80, typeId: UNIT_LANZENREITER_LR_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 90, typeId: UNIT_NOMADE_NO_ID },
        { amount: 90, typeId: UNIT_KOMPOSITBOGENSCHÜTZE_KBS_ID }
      ]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_NOMADE_NO_ID },
        { amount: 40, typeId: UNIT_KOMPOSITBOGENSCHÜTZE_KBS_ID }
      ]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 70, typeId: UNIT_NOMADE_NO_ID }]
    },
    {
      sector: 'F',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_NOMADE_NO_ID },
        { amount: 30, typeId: UNIT_LANZENREITER_LR_ID }
      ]
    },
    {
      sector: 'F',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 40, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BB_ID }]
    },
    {
      sector: 'F',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_NOMADE_NO_ID },
        { amount: 40, typeId: UNIT_KOMPOSITBOGENSCHÜTZE_KBS_ID }
      ]
    },
    {
      sector: 'F',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_NOMADE_NO_ID },
        { amount: 40, typeId: UNIT_LANZENREITER_LR_ID }
      ]
    },
    {
      sector: 'F',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 40, typeId: UNIT_BERITTENEAMAZONE_BA_ID }]
    },
    {
      sector: 'F',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_NOMADE_NO_ID },
        { amount: 60, typeId: UNIT_KOMPOSITBOGENSCHÜTZE_KBS_ID },
        { amount: 40, typeId: UNIT_LANZENREITER_LR_ID }
      ]
    }
  ]
} as KlassischesAt;
const SchieSSpulver = {
  name: 'Schießpulver',
  image: AT_SCHIESSPULVER_MAP,
  icon: AT_SCHIESSPULVER_ICON,
  id: AT_SCHIESSPULVER_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 8,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 50, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 120, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 40, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID },
        { amount: 50, typeId: UNIT_DESERTIERTEREITEREI_DC_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 30, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 90, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 40, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID },
        { amount: 30, typeId: UNIT_DESERTIERTEREITEREI_DC_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 90, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 90, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID },
        { amount: 30, typeId: UNIT_DESERTIERTEREITEREI_DC_ID }
      ]
    },
    {
      sector: 'C',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 100, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 70, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID },
        { amount: 80, typeId: UNIT_DESERTIERTEREITEREI_DC_ID }
      ]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 20, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 80, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 80, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID },
        { amount: 60, typeId: UNIT_DESERTIERTEREITEREI_DC_ID }
      ]
    },
    {
      sector: 'D',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 70, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 50, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'D',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 50, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 70, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 75, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 100, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID }
      ]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 70, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 70, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'E',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 100, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 50, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'F',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 100, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID }
      ]
    },
    {
      sector: 'F',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 80, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 70, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'F',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 80, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'F',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [{ amount: 200, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID }]
    },
    {
      sector: 'G',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 130, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 70, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'G',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 150, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID }
      ]
    },
    {
      sector: 'G',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 100, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 50, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'G',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 100, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID }
      ]
    },
    {
      sector: 'H',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 80, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID }
      ]
    },
    {
      sector: 'H',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 90, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID }
      ]
    },
    {
      sector: 'H',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 80, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 80, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID }
      ]
    },
    {
      sector: 'H',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 100, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID }
      ]
    },
    {
      sector: 'I',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 80, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID }
      ]
    },
    {
      sector: 'I',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 130, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID }
      ]
    },
    {
      sector: 'I',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID }]
    },
    {
      sector: 'I',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 100, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID }
      ]
    },
    {
      sector: 'J',
      number: 1,
      type: CAMP_TYPE_S,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 40, typeId: UNIT_DESERTIERTERKANONIER_DK_ID }
      ]
    },
    {
      sector: 'J',
      number: 2,
      type: CAMP_TYPE_S,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 50, typeId: UNIT_DESERTIERTERKANONIER_DK_ID }
      ]
    },
    {
      sector: 'J',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 70, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID }
      ]
    },
    {
      sector: 'J',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID }
      ]
    },
    {
      sector: 'J',
      number: 5,
      type: CAMP_TYPE_S,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 50, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID },
        { amount: 20, typeId: UNIT_DESERTIERTERKANONIER_DK_ID },
        { amount: 2, typeId: UNIT_DICKEBERTHA_DB_ID }
      ]
    }
  ]
} as KlassischesAt;
const SchlummerndesRiff = {
  name: 'Schlummerndes Riff',
  image: AT_SCHLUMMERNDESRIFF_MAP,
  icon: AT_SCHLUMMERNDESRIFF_ICON,
  id: AT_SCHLUMMERNDESRIFF_ID,
  campaign: ' ',
  type: MINI_MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 3,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID },
        { amount: 40, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 40, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 30, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 40, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID },
        { amount: 30, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 20, typeId: UNIT_DESERTIERTERKANONIER_DK_ID }
      ]
    },
    {
      sector: 'A',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 20, typeId: UNIT_DESERTIERTERKANONIER_DK_ID },
        { amount: 1, typeId: UNIT_SIRROBIN_ROB_ID }
      ]
    }
  ]
} as KlassischesAt;

const Seeufer_Schatz = {
  name: 'Seeufer-Schatz',
  image: AT_SEEUFER_SCHATZ_MAP,
  icon: AT_SEEUFER_SCHATZ_ICON,
  id: AT_SEEUFER_SCHATZ_ID,
  campaign: ' ',
  type: KOOP_MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 5,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_DUNKLERPRIESTER_DP_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_K,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 40, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 40, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_KULTIST_KT_ID },
        { amount: 40, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 40, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_K,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 1, typeId: UNIT_DUNKLERHOHEPRIESTER_DHP_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KULTIST_KT_ID },
        { amount: 10, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 20, typeId: UNIT_TANZENDERDERWISCH_TD_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_H,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 1, typeId: UNIT_SUMPFHEXE_SH_ID },
        { amount: 1, typeId: UNIT_DUNKLERHOHEPRIESTER_DHP_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_DUNKLERPRIESTER_DP_ID }
      ]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_K,
      art: '',
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 40, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 40, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_KULTIST_KT_ID },
        { amount: 40, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 40, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_K,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 1, typeId: UNIT_DUNKLERHOHEPRIESTER_DHP_ID }
      ]
    },
    {
      sector: 'F',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KULTIST_KT_ID },
        { amount: 10, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'F',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 20, typeId: UNIT_TANZENDERDERWISCH_TD_ID }
      ]
    },
    {
      sector: 'F',
      number: 3,
      type: CAMP_TYPE_H,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_KULTIST_KT_ID },
        { amount: 50, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 1, typeId: UNIT_SUMPFHEXE_SH_ID },
        { amount: 1, typeId: UNIT_DUNKLERHOHEPRIESTER_DHP_ID }
      ]
    }
  ]
} as KlassischesAt;
const Sumpfhexe = {
  name: 'Sumpfhexe',
  image: AT_SUMPFHEXE_MAP,
  icon: AT_SUMPFHEXE_ICON,
  id: AT_SUMPFHEXE_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 3,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [{ amount: 40, typeId: UNIT_KULTIST_KT_ID }]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 50, typeId: UNIT_KULTIST_KT_ID }]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_KULTIST_KT_ID },
        { amount: 40, typeId: UNIT_FANATIKER_FA_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 60, typeId: UNIT_KULTIST_KT_ID }]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 60, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 33, typeId: UNIT_KULTIST_KT_ID },
        { amount: 33, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 33, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [{ amount: 120, typeId: UNIT_KULTIST_KT_ID }]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 60, typeId: UNIT_DUNKLERPRIESTER_DP_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_KULTIST_KT_ID },
        { amount: 120, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 1, typeId: UNIT_DUNKLERHOHEPRIESTER_DHP_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_H,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 99, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 1, typeId: UNIT_SUMPFHEXE_SH_ID }
      ]
    }
  ]
} as KlassischesAt;
const SÖhnederSteppe = {
  name: 'Söhne der Steppe',
  image: AT_SÖHNEDERSTEPPE_MAP,
  icon: AT_SÖHNEDERSTEPPE_ICON,
  id: AT_SÖHNEDERSTEPPE_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 8,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 80, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 2, typeId: UNIT_STINKTIER_ST_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 80, typeId: UNIT_STEINWERFER_SW_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 1, typeId: UNIT_EINÄUGIGERBERT_EB_ID }
      ]
    },
    {
      sector: 'A',
      number: 6,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_WACHHUND_WH_ID }]
    },
    {
      sector: 'A',
      number: 7,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 160, typeId: UNIT_SCHLÄGER_SL_ID }]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 80, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 60, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 1, typeId: UNIT_STINKTIER_ST_ID },
        { amount: 1, typeId: UNIT_EINÄUGIGERBERT_EB_ID }
      ]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_WACHHUND_WH_ID }]
    },
    {
      sector: 'B',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'B',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 2, typeId: UNIT_STINKTIER_ST_ID }
      ]
    },
    {
      sector: 'B',
      number: 7,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 160, typeId: UNIT_SCHLÄGER_SL_ID }]
    },
    {
      sector: 'B',
      number: 8,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 80, typeId: UNIT_STEINWERFER_SW_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 1, typeId: UNIT_EINÄUGIGERBERT_EB_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 80, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_STEINWERFER_SW_ID },
        { amount: 50, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    },
    {
      sector: 'C',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    },
    {
      sector: 'C',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 60, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 60, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_METALLGEBISS_MG_ID }
      ]
    },
    {
      sector: 'C',
      number: 6,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 7,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 55, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 55, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 55, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 8,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_STINKTIER_ST_ID },
        { amount: 1, typeId: UNIT_EINÄUGIGERBERT_EB_ID }
      ]
    },
    {
      sector: 'C',
      number: 9,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 1, typeId: UNIT_STINKTIER_ST_ID },
        { amount: 2, typeId: UNIT_EINÄUGIGERBERT_EB_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 80, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 60, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 60, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_METALLGEBISS_MG_ID }
      ]
    },
    {
      sector: 'D',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_STEINWERFER_SW_ID },
        { amount: 50, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    },
    {
      sector: 'D',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    },
    {
      sector: 'D',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_STINKTIER_ST_ID },
        { amount: 1, typeId: UNIT_EINÄUGIGERBERT_EB_ID }
      ]
    },
    {
      sector: 'D',
      number: 7,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 55, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 55, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 55, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'D',
      number: 8,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'D',
      number: 9,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 1, typeId: UNIT_STINKTIER_ST_ID },
        { amount: 2, typeId: UNIT_EINÄUGIGERBERT_EB_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 60, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'E',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 80, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 40, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'E',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_METALLGEBISS_MG_ID }
      ]
    },
    {
      sector: 'F',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [{ amount: 150, typeId: UNIT_RAUFBOLD_RB_ID }]
    },
    {
      sector: 'F',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [{ amount: 150, typeId: UNIT_RAUFBOLD_RB_ID }]
    },
    {
      sector: 'F',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [{ amount: 150, typeId: UNIT_RAUFBOLD_RB_ID }]
    },
    {
      sector: 'F',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'F',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    },
    {
      sector: 'F',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 70, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 60, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_CHUCK_CK_ID }
      ]
    }
  ]
} as KlassischesAt;
const Tropensonne = {
  name: 'Tropensonne',
  image: AT_TROPENSONNE_MAP,
  icon: AT_TROPENSONNE_ICON,
  id: AT_TROPENSONNE_ID,
  campaign: ' ',
  type: MINI_MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 2,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 30, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 30, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_P,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_MESSERWERFER_MW_ID },
        { amount: 20, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 20, typeId: UNIT_MAAT_MA_ID },
        { amount: 1, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 40, typeId: UNIT_KRÄHENFÜSSE_KF_ID }
      ]
    },
    {
      sector: 'A',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 40, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 20, typeId: UNIT_KRÄHENFÜSSE_KF_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 40, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 30, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 30, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 30, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 1, typeId: UNIT_STINKTIER_ST_ID }
      ]
    }
  ]
} as KlassischesAt;
const VerrÄter = {
  name: 'Verräter',
  image: AT_VERRÄTER_MAP,
  icon: AT_VERRÄTER_ICON,
  id: AT_VERRÄTER_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 6,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'leicht',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 60, typeId: UNIT_DESERTIERTEREITEREI_DC_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [{ amount: 80, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID }]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 40, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 20, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 60, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID }
      ]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 100, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 150, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 100, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 60, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID },
        { amount: 1, typeId: UNIT_SIRROBIN_ROB_ID },
        { amount: 1, typeId: UNIT_DICKEBERTHA_DB_ID }
      ]
    }
  ]
} as KlassischesAt;
const ViktorderVerschlagene = {
  name: 'Viktor der Verschlagene',
  image: AT_VIKTORDERVERSCHLAGENE_MAP,
  icon: AT_VIKTORDERVERSCHLAGENE_ICON,
  id: AT_VIKTORDERVERSCHLAGENE_ID,
  campaign: ' ',
  type: MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 7,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    },
    {
      sector: 'A',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    },
    {
      sector: 'A',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 99, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 1, typeId: UNIT_EINÄUGIGERBERT_EB_ID }
      ]
    },
    {
      sector: 'B',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'B',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'B',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    },
    {
      sector: 'B',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'B',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 99, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 1, typeId: UNIT_EINÄUGIGERBERT_EB_ID }
      ]
    },
    {
      sector: 'C',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    },
    {
      sector: 'C',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 5,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    },
    {
      sector: 'C',
      number: 6,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 99, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 1, typeId: UNIT_EINÄUGIGERBERT_EB_ID }
      ]
    },
    {
      sector: 'D',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'D',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 120, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    },
    {
      sector: 'D',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 80, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 60, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_EINÄUGIGERBERT_EB_ID }
      ]
    },
    {
      sector: 'E',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'E',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    },
    {
      sector: 'E',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 90, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 1, typeId: UNIT_STINKTIER_ST_ID },
        { amount: 1, typeId: UNIT_EINÄUGIGERBERT_EB_ID }
      ]
    },
    {
      sector: 'F',
      number: 1,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 100, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'F',
      number: 2,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 150, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'F',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'F',
      number: 4,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    },
    {
      sector: 'F',
      number: 5,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 155, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 1, typeId: UNIT_METALLGEBISS_MG_ID }
      ]
    },
    {
      sector: 'F',
      number: 6,
      type: CAMP_TYPE_W,
      art: '',
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_GRAUERWOLF_W_ID }]
    },
    {
      sector: 'G',
      number: 1,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 80, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 40, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'G',
      number: 2,
      type: CAMP_TYPE_N,
      art: 'mittel',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 80, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'G',
      number: 3,
      type: CAMP_TYPE_N,
      art: 'schwer',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_STINKTIER_ST_ID }
      ]
    },
    {
      sector: 'G',
      number: 4,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 2, typeId: UNIT_STINKTIER_ST_ID },
        { amount: 1, typeId: UNIT_WILDEWALTRAUD_WW_ID }
      ]
    }
  ]
} as KlassischesAt;
const WinterWunderland10 = {
  name: 'Winter Wunderland 10',
  image: AT_WINTERWUNDERLAND10_MAP,
  icon: AT_WINTERWUNDERLAND10_ICON,
  id: AT_WINTERWUNDERLAND10_ID,
  campaign: 'Winter Wunderland',
  type: UNTERNEHMUNG_TYPE,
  theme: WEIHNACHTEN_THEME,
  difficulty: 10,
  allowsElite: Ja,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 25, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 25, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 15, typeId: UNIT_FROSTLEOPARD_FLE_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 20, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 20, typeId: UNIT_FROSTLEOPARD_FLE_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 25, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 25, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 1, typeId: UNIT_RIBBITHA10_RIBC_ID }
      ]
    }
  ]
} as KlassischesAt;
const WinterWunderland4 = {
  name: 'Winter Wunderland 4',
  image: AT_WINTERWUNDERLAND4_MAP,
  icon: AT_WINTERWUNDERLAND4_ICON,
  id: AT_WINTERWUNDERLAND4_ID,
  campaign: 'Winter Wunderland',
  type: UNTERNEHMUNG_TYPE,
  theme: WEIHNACHTEN_THEME,
  difficulty: 4,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 10, typeId: UNIT_FROSTLEOPARD_FLE_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 20, typeId: UNIT_FROSTBÄR_FBR_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 10, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 10, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 1, typeId: UNIT_RIBBITHA4_RIBA_ID }
      ]
    }
  ]
} as KlassischesAt;

const WinterWunderland7 = {
  name: 'Winter Wunderland 7',
  image: AT_WINTERWUNDERLAND7_MAP,
  icon: AT_WINTERWUNDERLAND7_ICON,
  id: AT_WINTERWUNDERLAND7_ID,
  campaign: 'Winter Wunderland',
  type: UNTERNEHMUNG_TYPE,
  theme: WEIHNACHTEN_THEME,
  difficulty: 7,
  allowsElite: Nein,
  camps: [
    {
      sector: 'A',
      number: 1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 25, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 10, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 10, typeId: UNIT_FROSTLEOPARD_FLE_ID }
      ]
    },
    {
      sector: 'A',
      number: 2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 10, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 10, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 30, typeId: UNIT_FROSTBÄR_FBR_ID }
      ]
    },
    {
      sector: 'A',
      number: 3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 10, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 20, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 1, typeId: UNIT_RIBBITHA7_RIBB_ID }
      ]
    }
  ]
} as KlassischesAt;

const NebligerKuchen1 = {
  name: 'Nebelhafter Kuchen (leicht)',
  image: AT_NEBLIGERKUCHEN_MAP,
  icon: AT_NEBLIGERKUCHEN_ICON,
  id: AT_NEBLIGERKUCHEN1_ID,
  campaign: '',
  type: MISSION_TYPE,
  theme: RETRO_THEME,
  difficulty: 7,
  allowsElite: Ja,
  camps: [
    {
      sector: 'A',
      number: 1.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 85, typeId: UNIT_SCHLÄGER_SL_ID }
      ]
    },
    {
      sector: 'A',
      number: 1.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 55, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 1.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 75, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'A',
      number: 2.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 2.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    },
    {
      sector: 'A',
      number: 2.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'A',
      number: 3.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 1, typeId: UNIT_CHUCK_CK_ID },
        { amount: 95, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 25, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'A',
      number: 3.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 1, typeId: UNIT_CHUCK_CK_ID },
        { amount: 65, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 50, typeId: UNIT_SCHLÄGER_SL_ID }
      ]
    },
    {
      sector: 'A',
      number: 3.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 1, typeId: UNIT_METALLGEBISS_MG_ID },
        { amount: 65, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 60, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'B',
      number: 1.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 10, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 35, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 100, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'B',
      number: 1.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 80, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 45, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    },
    {
      sector: 'B',
      number: 1.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 95, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 80, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'B',
      number: 2.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 35, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 75, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 10, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'B',
      number: 2.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 55, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 30, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 40, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'B',
      number: 2.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 95, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    },
    {
      sector: 'B',
      number: 3.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 1, typeId: UNIT_CHUCK_CK_ID },
        { amount: 25, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 55, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    },
    {
      sector: 'B',
      number: 3.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 1, typeId: UNIT_METALLGEBISS_MG_ID },
        { amount: 30, typeId: UNIT_STEINWERFER_SW_ID },
        { amount: 70, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'B',
      number: 3.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 1, typeId: UNIT_CHUCK_CK_ID },
        { amount: 80, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'C',
      number: 1.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 65, typeId: UNIT_STEINWERFER_SW_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 1.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 85, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 70, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'C',
      number: 1.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 45, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 70, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'C',
      number: 2.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 1, typeId: UNIT_CHUCK_CK_ID },
        { amount: 65, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 30, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'C',
      number: 2.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 1, typeId: UNIT_METALLGEBISS_MG_ID },
        { amount: 50, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 75, typeId: UNIT_STEINWERFER_SW_ID }
      ]
    },
    {
      sector: 'C',
      number: 2.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 1, typeId: UNIT_METALLGEBISS_MG_ID },
        { amount: 90, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 40, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'C',
      number: 3.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 50, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'C',
      number: 3.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID }
      ]
    },
    {
      sector: 'C',
      number: 3.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 10, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 50, typeId: UNIT_RAUFBOLD_RB_ID }
      ]
    }
  ]
} as KlassischesAt;

const NebligerKuchen2 = {
  name: 'Nebelhafter Kuchen (mittel)',
  image: AT_NEBLIGERKUCHEN_MAP,
  icon: AT_NEBLIGERKUCHEN_ICON,
  id: AT_NEBLIGERKUCHEN2_ID,
  campaign: '',
  type: MISSION_TYPE,
  theme: RETRO_THEME,
  difficulty: 7,
  allowsElite: Ja,
  camps: [
    {
      sector: 'A',
      number: 1.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 150, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 75, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'A',
      number: 1.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 100, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 50, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'A',
      number: 1.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 150, typeId: UNIT_MESSERWERFER_MW_ID },
        { amount: 75, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'A',
      number: 2.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 100, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 200, typeId: UNIT_SÄBELRASSLER_SÄR_ID }
      ]
    },
    {
      sector: 'A',
      number: 2.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 150, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 100, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID }
      ]
    },
    {
      sector: 'A',
      number: 2.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 100, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 150, typeId: UNIT_MESSERWERFER_MW_ID }
      ]
    },
    {
      sector: 'A',
      number: 3.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 2, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID },
        { amount: 50, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'A',
      number: 3.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 2, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID },
        { amount: 125, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 50, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'A',
      number: 3.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 2, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID },
        { amount: 75, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'B',
      number: 1.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 75, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 100, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'B',
      number: 1.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 100, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'B',
      number: 1.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 100, typeId: UNIT_MESSERWERFER_MW_ID },
        { amount: 75, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'B',
      number: 2.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 75, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 100, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'B',
      number: 2.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 100, typeId: UNIT_MESSERWERFER_MW_ID },
        { amount: 100, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'B',
      number: 2.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 100, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 200, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID }
      ]
    },
    {
      sector: 'B',
      number: 3.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_DECKSCHRUBBER_DSR_ID },
        { amount: 150, typeId: UNIT_MAAT_MA_ID },
        { amount: 2, typeId: UNIT_SIRROBIN_ROB_ID }
      ]
    },
    {
      sector: 'B',
      number: 3.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 100, typeId: UNIT_MAAT_MA_ID },
        { amount: 2, typeId: UNIT_SIRROBIN_ROB_ID }
      ]
    },
    {
      sector: 'B',
      number: 3.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 150, typeId: UNIT_MAAT_MA_ID },
        { amount: 2, typeId: UNIT_SIRROBIN_ROB_ID }
      ]
    },
    {
      sector: 'C',
      number: 1.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 50, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 125, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'C',
      number: 1.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 200, typeId: UNIT_DECKSCHRUBBER_DSR_ID }
      ]
    },
    {
      sector: 'C',
      number: 1.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 100, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 50, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'C',
      number: 2.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 2, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID },
        { amount: 100, typeId: UNIT_MAAT_MA_ID },
        { amount: 2, typeId: UNIT_BRÜLLENDERSTIER_BS_ID }
      ]
    },
    {
      sector: 'C',
      number: 2.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 2, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID },
        { amount: 100, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 75, typeId: UNIT_MAAT_MA_ID },
        { amount: 4, typeId: UNIT_BRÜLLENDERSTIER_BS_ID }
      ]
    },
    {
      sector: 'C',
      number: 2.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 125, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 3, typeId: UNIT_VERRÜCKTERSMUTJE_VS_ID },
        { amount: 75, typeId: UNIT_MAAT_MA_ID },
        { amount: 2, typeId: UNIT_BRÜLLENDERSTIER_BS_ID }
      ]
    },
    {
      sector: 'C',
      number: 3.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_SÄBELRASSLER_SÄR_ID },
        { amount: 175, typeId: UNIT_PISTOLENSCHÜTZE_PS_ID },
        { amount: 25, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'C',
      number: 3.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 125, typeId: UNIT_MAAT_MA_ID }
      ]
    },
    {
      sector: 'C',
      number: 3.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
        { amount: 150, typeId: UNIT_MAAT_MA_ID }
      ]
    }
  ]
} as KlassischesAt;

const NebligerKuchen3 = {
  name: 'Nebelhafter Kuchen (schwer)',
  image: AT_NEBLIGERKUCHEN_MAP,
  icon: AT_NEBLIGERKUCHEN_ICON,
  id: AT_NEBLIGERKUCHEN3_ID,
  campaign: '',
  type: MISSION_TYPE,
  theme: RETRO_THEME,
  difficulty: 7,
  allowsElite: Ja,
  camps: [
    {
      sector: 'A',
      number: 1.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 25, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 75, typeId: UNIT_FROSTLEOPARD_FLE_ID }
      ]
    },
    {
      sector: 'A',
      number: 1.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 25, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 25, typeId: UNIT_FROSTADLER_FAD_ID }
      ]
    },
    {
      sector: 'A',
      number: 1.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 20, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 70, typeId: UNIT_FROSTADLER_FAD_ID }
      ]
    },
    {
      sector: 'A',
      number: 2.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 50, typeId: UNIT_FROSTADLER_FAD_ID }
      ]
    },
    {
      sector: 'A',
      number: 2.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID }
      ]
    },
    {
      sector: 'A',
      number: 2.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 50, typeId: UNIT_FROSTWOLF_FWO_ID }
      ]
    },
    {
      sector: 'A',
      number: 3.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 50, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 1, typeId: UNIT_GRAUSIGERWOLF_GWO_ID }
      ]
    },
    {
      sector: 'A',
      number: 3.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 25, typeId: UNIT_FROSTLEOPARD_FLE_ID },
        { amount: 1, typeId: UNIT_GRAUSIGERWOLF_GWO_ID }
      ]
    },
    {
      sector: 'A',
      number: 3.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 25, typeId: UNIT_FROSTRIESE_FRI_ID },
        { amount: 1, typeId: UNIT_GRAUSIGERWOLF_GWO_ID }
      ]
    },
    {
      sector: 'B',
      number: 1.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 25, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 50, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 55, typeId: UNIT_FROSTWOLF_FWO_ID }
      ]
    },
    {
      sector: 'B',
      number: 1.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 20, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 70, typeId: UNIT_FROSTADLER_FAD_ID }
      ]
    },
    {
      sector: 'B',
      number: 1.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 30, typeId: UNIT_FROSTRIESE_FRI_ID }
      ]
    },
    {
      sector: 'B',
      number: 2.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 25, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 50, typeId: UNIT_FROSTRIESE_FRI_ID }
      ]
    },
    {
      sector: 'B',
      number: 2.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 35, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 30, typeId: UNIT_FROSTADLER_FAD_ID }
      ]
    },
    {
      sector: 'B',
      number: 2.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 35, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 75, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 25, typeId: UNIT_FROSTLEOPARD_FLE_ID }
      ]
    },
    {
      sector: 'B',
      number: 3.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 1, typeId: UNIT_WILDEROCHSE_ROX_ID },
        { amount: 30, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 50, typeId: UNIT_FROSTRIESE_FRI_ID }
      ]
    },
    {
      sector: 'B',
      number: 3.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 1, typeId: UNIT_WILDEROCHSE_ROX_ID },
        { amount: 50, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID }
      ]
    },
    {
      sector: 'B',
      number: 3.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 1, typeId: UNIT_RIESENLUCHS_GL_ID },
        { amount: 80, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 50, typeId: UNIT_WACHHUND_WH_ID }
      ]
    },
    {
      sector: 'C',
      number: 1.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 50, typeId: UNIT_FROSTWOLF_FWO_ID }
      ]
    },
    {
      sector: 'C',
      number: 1.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 25, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 50, typeId: UNIT_FROSTRIESE_FRI_ID }
      ]
    },
    {
      sector: 'C',
      number: 1.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 100, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 25, typeId: UNIT_FROSTADLER_FAD_ID }
      ]
    },
    {
      sector: 'C',
      number: 2.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 1, typeId: UNIT_FROSTBÄRENMATRIARCHIN_FBM_ID },
        { amount: 25, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 35, typeId: UNIT_FROSTRIESE_FRI_ID }
      ]
    },
    {
      sector: 'C',
      number: 2.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 1, typeId: UNIT_FROSTBÄRENMATRIARCHIN_FBM_ID },
        { amount: 50, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID }
      ]
    },
    {
      sector: 'C',
      number: 2.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [
        { amount: 1, typeId: UNIT_FROSTBÄRENMATRIARCHIN_FBM_ID },
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 25, typeId: UNIT_FROSTADLER_FAD_ID }
      ]
    },
    {
      sector: 'C',
      number: 3.1,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 190, typeId: UNIT_FROSTSTEINBOCK_FSB_ID }]
    },
    {
      sector: 'C',
      number: 3.2,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 160, typeId: UNIT_FROSTADLER_FAD_ID }]
    },
    {
      sector: 'C',
      number: 3.3,
      type: CAMP_TYPE_N,
      art: '',
      boss: Nein,
      units: [{ amount: 187, typeId: UNIT_FROSTLEOPARD_FLE_ID }]
    }
  ]
} as KlassischesAt;

export function getClassicAdventureById(id: KlassischeIds): KlassischesAt {
  const myId = id;
  return getClassicAdventureByExpression((at): at is KlassischesAt => at.id === myId)[0];
}

export function getAllClassicAdventures(): KlassischesAt[] {
  return getClassicAdventureByExpression();
}

export function getClassicAdventureByExpression(
  expr: (at: KlassischesAt) => at is KlassischesAt = (at): at is KlassischesAt => true
): KlassischesAt[] {
  const ats = [
    AlteBekannte,
    AlteRuinen,
    Archipel,
    ArktischeExplosion,
    Beutelschneider,
    BonabertiBusiness,
    DasEndederWelt,
    DasdunkleTor,
    DerSchamane,
    DerWirbelwind,
    DergestohleneSchlitten,
    DerschlafendeVulkan,
    DerverscholleneSchÄdel,
    DerÜberraschungsangriff,
    DieBelagerung,
    DieDunkleBruderschaft,
    DieFreibeuter_Razzia,
    DiegeteilteStadtimHerbst,
    DiegeteilteStadtimSommer,
    DieInselderFreibeuter,
    DieInvasionderNordmÄnner,
    DieNordmÄnner,
    DieSchwarzenPriester,
    DieSchwarzenRitter,
    DieStÖrenfriede,
    DieWildeWaltraut,
    DrohendeGefahr,
    EinsameExperimente,
    GarrunderFallensteller,
    GefÄngnisinsel,
    GrabrÄuber,
    Heimatinsel,
    JagdaufdieJÄger,
    KopfgeldjÄger,
    MehreinsameExperimente,
    MonsterausdemNebel,
    NebligerKuchen1,
    NebligerKuchen2,
    NebligerKuchen3,
    Mutterliebe,
    Piratenleben,
    RasenderBulle,
    RettetdasWeihnachtsfest_2013_,
    RÄuber_Gegenschlag,
    RÄuberbande,
    RÄubernest,
    RÜckkehrinsRÄubernest,
    Sattelfest,
    SchieSSpulver,
    SchlummerndesRiff,
    Seeufer_Schatz,
    Sumpfhexe,
    SÖhnederSteppe,
    Tropensonne,
    VerrÄter,
    ViktorderVerschlagene,
    WinterWunderland10,
    WinterWunderland4,
    WinterWunderland7
  ];
  return ats.filter(expr).map(at => ({
    ...at,
    camps: at.camps.map(camp => ({
      ...camp,
      units: camp.units.map(unit => ({ ...unit }))
    }))
  }));
}
